import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useCoreApiTokenProvider } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { CLIENT_ID } from "@api/client-id";
import { ProjectApi } from "@api/project-api/project-api";
import { useMemo } from "react";

/** Custom hook that initializes the project API client and returns an instance of it */
export function useProjectApiClient({
  projectId,
}: BaseProjectIdProps): ProjectApi {
  const tokenProvider = useCoreApiTokenProvider({
    coreApiBaseUrl: runtimeConfig.urls.apiBaseUrl,
    projectId: projectId.toString(),
  });

  // Make sure the client is only created once, unless projectId or token provider change
  return useMemo(
    () =>
      new ProjectApi({
        projectApi: new URL(runtimeConfig.urls.projectApiUrl),
        projectId: projectId.toString(),
        tokenProvider,
        clientId: CLIENT_ID,
      }),
    [projectId, tokenProvider]
  );
}
