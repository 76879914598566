import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { fetchCompanyMemberGroups } from "@store/company-member-groups/company-member-groups-thunk";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  companyMemberGroupsSelector,
  companyMemberGroupsStatusSelector,
} from "@store/company-member-groups/company-member-groups-selector";
import { FetchingStatus } from "@store/store-types";
import { resetCompanyMemberGroupsState } from "@store/company-member-groups/company-member-groups-slice";

interface UseMemberGroups {
  /** Groups that the member belongs to */
  groups: SphereDashboardAPITypes.CompanyMemberGroup[] | null;

  /** The status of the endpoint */
  companyMemberGroupsStatus: FetchingStatus;
}

/** Fetches the groups of a company member */
export function useMemberGroups(): UseMemberGroups {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { memberId, teamId } = useAppParams();
  const groups = useAppSelector(companyMemberGroupsSelector);
  const companyMemberGroupsStatus = useAppSelector(
    companyMemberGroupsStatusSelector
  );

  // Fetch the company members groups
  useEffect(() => {
    const id = memberId || teamId;
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(fetchCompanyMemberGroups({ coreApiClient, memberId: id }));
    }

    return () => {
      /* Clean the store for the company members group */
      dispatch(resetCompanyMemberGroupsState());
    };
  }, [coreApiClient, dispatch, memberId, teamId]);

  return { groups, companyMemberGroupsStatus };
}
