import { FaroTextButton } from "@components/common/faro-text-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName, updateOne } from "@store/table/table-slice";
import { selectedProjectManagerSelector } from "@store/projects/projects-selector";
import { useEffect, useState } from "react";
import { useRemoveMemberBulkDialogContent } from "@components/table/members/bulk-actions/bulk-remove-member-helper";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { removeMemberFromProject } from "@store/projects/projects-slice-thunk";
import {
  BaseCompanyIdProps,
  BaseGroupIdProps,
  BaseProjectIdProps,
  BaseTeamIdProps,
} from "@custom-types/sdb-company-types";
import { BaseMemberAndTeam } from "@custom-types/member-types";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { removeMemberFromCompany } from "@store/members/members-slice-thunk";
import { removeMemberFromGroup } from "@store/groups/groups-slice";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";
import { removeMembersFromTeam } from "@store/teams/teams-slice-thunk";
import { removeMembersByIds } from "@store/teams/teams-slice";

interface BaseRemoveProps extends BaseCompanyIdProps {
  /**
   * Title for the bulk action button.
   * @default "members" - The button will display "Remove members".
   * Optionally, it can be customized, e.g., "from workspace", displaying "Remove from workspace".
   */
  title?: string;
}
// All types are needed to be able to use the component in different contexts.
// However, "never" type is used to make sure that the irrelevant keys should not be passed.
interface RemoveFromCompanyProps extends BaseRemoveProps {
  /** The table subject type of the table */
  subjectType: "workspace";

  projectId?: never;
  groupId?: never;
  onGroupRemoved?: never;
  teamId?: never;
}

interface RemoveFromProjectProps extends BaseRemoveProps, BaseProjectIdProps {
  /** The table subject of the table */
  subjectType: "project";

  groupId?: never;
  onGroupRemoved?: never;
  teamId?: never;
}

interface RemoveFromGroupProps extends BaseRemoveProps, BaseGroupIdProps {
  /** The table subject of the table */
  subjectType: "group";

  /** Callback when the group is removed */
  onGroupRemoved: () => void;

  projectId?: never;
  teamId?: never;
}

interface RemoveFromTeamProps extends BaseRemoveProps, BaseTeamIdProps {
  /** The table subject type of the table */
  subjectType: "team";

  projectId?: never;
  groupId?: never;
  onGroupRemoved?: never;
}

interface RemoveFromWorkspaceTeamProps
  extends BaseRemoveProps,
    BaseTeamIdProps {
  /** The table subject type of the table */
  subjectType: "workspaceTeam";

  projectId?: never;
  groupId?: never;
  onGroupRemoved?: never;
}

/** All the possible options to remove a member from context */
type RemoveMember =
  | RemoveFromCompanyProps
  | RemoveFromProjectProps
  | RemoveFromGroupProps
  | RemoveFromTeamProps
  | RemoveFromWorkspaceTeamProps;

/** Show remove member button and dispatch action where the bulk dialog will act accordingly */
export function BulkRemoveMember({
  companyId,
  projectId,
  groupId,
  teamId,
  subjectType,
  onGroupRemoved,
  title = "Remove members",
}: RemoveMember): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);

  const { user: selectedProjectManager } = useAppSelector(
    selectedProjectManagerSelector
  );
  const bulkActionName = useAppSelector(bulkActionNameSelector);

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );

  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));
  const selectedTeams = useAppSelector(selectedEntitiesSelector("teams"));

  const { bulkDialogContent } = useRemoveMemberBulkDialogContent(subjectType);

  // Closes the dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onRemoveMemberConfirmed(
    member: BaseMemberAndTeam
  ): Promise<unknown> {
    switch (subjectType) {
      case "workspace":
        return dispatch(
          removeMemberFromCompany({
            coreApiClient,
            companyId,
            member,
          })
        ).unwrap();

      case "workspaceTeam":
        return dispatch(
          removeMemberFromCompany({
            coreApiClient,
            companyId,
            member,
          })
        )
          .unwrap()
          .then(() => {
            return dispatch(removeMembersByIds([member.identity]));
          });

      case "project":
        return dispatch(
          removeMemberFromProject({
            coreApiClient,
            companyId,
            member,
            projectId,
          })
        ).unwrap();

      case "group":
        return dispatch(
          removeMemberFromGroup({
            coreApiClient,
            companyId,
            member,
            groupId,
            onGroupRemoved,
          })
        ).unwrap();

      case "team":
        return dispatch(
          removeMembersFromTeam({
            coreApiClient,
            companyId,
            teamId,
            memberIds: [member.identity],
          })
        ).unwrap();
    }
  }

  function onRemoveMemberClick(): void {
    dispatch(setBulkActionName("removeMember"));

    if (subjectType === "project" && selectedProjectManager) {
      dispatch(
        updateOne({
          id: selectedProjectManager.identity,
          changes: {
            status: "not-allowed",
            message: "Cannot remove owner of project",
          },
        })
      );
    }

    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextButton
        onClick={onRemoveMemberClick}
        tooltipText={`Remove member from ${subjectType}`}
        sx={{ fontWeight: 600 }}
      >
        {title}
      </FaroTextButton>

      {bulkActionName === "removeMember" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkRemoveMember}
          bulkActionCallback={onRemoveMemberConfirmed}
          selectedEntities={[...selectedEntities, ...selectedTeams]}
          uniqueIdKey="identity"
          tableDataType="members"
        />
      )}
    </>
  );
}
