import { SelectProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import {
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TEXT_FONT_SIZE,
  DISABLED_OPACITY,
  placeholderItalicSx,
} from "@styles/common-styles";
import { getSvgColoredIconCss, border } from "@utils/ui-utils";
import { CSSProperties } from "react";

export type FaroSelectValue = string | number;

export interface Props<Value extends FaroSelectValue>
  extends Pick<
    SelectProps<Value>,
    | "placeholder"
    | "size"
    | "value"
    | "children"
    | "labelId"
    | "id"
    | "renderValue"
    | "sx"
  > {
  /**
   * The variant of the select, limited to the options we have a custom style for
   * - "outlined" - It is used inside dialogs and has a white background
   * - "standard" - It is used in the info bar and does not have an outline
   * - "tableCell" - It is used inside table cells and has a white background with no border
   */
  variant:
    | Extract<SelectProps<Value>["variant"], "outlined" | "standard">
    | "tableCell";

  /** If true, a value is displayed even if no items are selected. */
  shouldDisplayEmpty?: SelectProps<Value>["displayEmpty"];

  /** If true, the select shows in an error style */
  shouldShowError?: boolean;

  /** If true the items shows in full width */
  shouldShowFullWidth?: boolean;

  /** The label to show for the select field. Only available for standard variant. */
  label?: string;

  /** Callback fired when the value changes. */
  onChange: (value: Value) => void;

  /** Callback function to reset the state of the selector */
  onResetSelection?: () => void;
}

/**
 * Defines the sx style for the standard variant of the select.
 */
const sxStandardSelect: SelectProps["sx"] = {
  // Style that only applies to the "standard" variant (MuiInput-underline) when it is not focused
  "&.MuiInput-underline:not(.Mui-focused)": {
    // Hide the caret icon by default
    "&.MuiInputBase-root .MuiSelect-icon": {
      display: "none",
    },

    "&.MuiInputBase-root:hover": {
      // Show the caret icon on hover
      "& .MuiSelect-icon": {
        display: "block",
      },

      // Hide the line for the select on hover
      "&.MuiInput-underline:before": {
        border: "none",
      },
    },

    // Hide the default line for the select
    "&.MuiInput-underline:before": {
      border: "none",
    },
  },

  // Style that only applies to the "standard" variant (MuiInput-underline) when it is focused
  "&.MuiInput-underline.Mui-focused": {
    "&.MuiInputBase-root .MuiSelect-icon": {
      ...getSvgColoredIconCss(sphereColors.blue500),
    },

    // Hide a strange background color that appears on the input when it is focused
    "& .MuiInputBase-input": {
      background: "none",
    },

    // Always show the caret icon when the select is focused
    "& .MuiSelect-icon": {
      display: "block",
    },

    // Show the blue line for the select when it is focused
    "&.MuiInput-underline:before": {
      borderBottom: border(sphereColors.blue500),
    },

    "&.MuiInput-underline:after": {
      // There is another border that appears when the select is focused, we hide it
      border: "none",
    },
  },
};

/** Defines the sx style for the tableCell variant of the select. */
const sxTableCellSelect: SelectProps["sx"] = {
  // Style that only applies to the "tableCell" variant (MuiInput-underline) when it is not focused
  "&.MuiInput-underline:not(.Mui-focused)": {
    "&.MuiInputBase-root:hover": {
      // Hide the line for the select on hover
      "&.MuiInput-underline:before": {
        border: "none",
      },
    },

    // Hide the default line for the select
    "&.MuiInput-underline:before": {
      border: "none",
    },
  },

  // Style that only applies to the "tableCell" variant (MuiInput-underline) when it is focused
  "&.MuiInput-underline.Mui-focused": {
    "&.MuiInputBase-root .MuiSelect-icon": {
      ...getSvgColoredIconCss(sphereColors.blue500),
    },

    // Hide a strange background color that appears on the input when it is focused
    "& .MuiInputBase-input": {
      background: "none",
    },

    // Always show the caret icon when the select is focused
    "& .MuiSelect-icon": {
      display: "block",
    },

    // Show the blue line for the select when it is focused
    "&.MuiInput-underline:before": {
      borderBottom: border(sphereColors.blue500),
    },

    "&.MuiInput-underline:after": {
      // There is another border that appears when the select is focused, we hide it
      border: "none",
    },
  },
};

export interface SelectStyleProps {
  /** The height of the input */
  inputHeight?: string;

  /** The max height of the input */
  inputMaxHeight?: string;

  /** The padding top of the input */
  inputPaddingTop?: string;

  /** The padding bottom of the input */
  inputPaddingBottom?: string;

  /** The font size of the input */
  inputFontSize?: string;

  /** The font color of the input */
  inputColor?: string;

  /** The font weight of the input */
  inputFontWeight?: string;

  /** The custom style for the placeholder */
  placeHolderStyle?: SelectProps["sx"];

  /** The font family of the placeholder */
  placeHolderFontFamily?: CSSProperties["fontFamily"];

  /** The opacity of the input */
  inputOpacity?: number;

  /** The custom style for the select */
  selectSx?: SelectProps["sx"];
}

export function getSelectProps<Value extends FaroSelectValue>({
  variant,
  size,
  value,
}: Pick<Props<Value>, "variant" | "size" | "value">): SelectStyleProps {
  const hasValueSelected = !!value;

  /** Defines the custom style for the component if it is displaying the placeholder */
  const placeHolderStyle = hasValueSelected ? undefined : placeholderItalicSx;
  // Extracts the font family from the placeholder style
  const placeHolderFontFamily: CSSProperties["fontFamily"] | undefined =
    placeHolderStyle && "fontFamily" in placeHolderStyle
      ? placeHolderStyle.fontFamily
      : undefined;
  const inputOpacity = hasValueSelected ? 1 : DISABLED_OPACITY;

  if (variant === "outlined") {
    // eslint-disable-next-line no-console -- We want to log this error in dev mode
    console.assert(
      size === "small",
      "Only small size is supported for outlined variant"
    );
    return {
      inputHeight: "20px",
      inputMaxHeight: "20px",
      inputPaddingTop: "7px",
      inputPaddingBottom: "10px",
      inputFontSize: DEFAULT_INPUT_FONT_SIZE,
      inputColor: sphereColors.gray800,
      inputFontWeight: "400",
      placeHolderStyle,
      placeHolderFontFamily,
      inputOpacity,
      selectSx: {},
    };
  }

  if (variant === "standard") {
    // eslint-disable-next-line no-console -- We want to log this error in dev mode
    console.assert(
      size === "medium",
      "Only medium size is supported for standard variant"
    );
    return {
      inputHeight: undefined,
      inputMaxHeight: undefined,
      inputPaddingTop: undefined,
      inputPaddingBottom: undefined,
      inputFontSize: "18px",
      inputColor: sphereColors.blue500,
      inputFontWeight: "700",
      placeHolderStyle,
      placeHolderFontFamily,
      inputOpacity,
      selectSx: sxStandardSelect,
    };
  }

  if (variant === "tableCell") {
    return {
      inputHeight: "20px",
      inputMaxHeight: "20px",
      inputPaddingTop: "7px",
      inputPaddingBottom: "10px",
      inputFontSize: DEFAULT_TEXT_FONT_SIZE,
      inputColor: sphereColors.gray800,
      inputFontWeight: "400",
      placeHolderStyle,
      placeHolderFontFamily,
      inputOpacity,
      selectSx: sxTableCellSelect,
    };
  }

  // This should not happen, but just in case we return an empty object
  return {};
}
