import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroSwitch } from "@components/common/faro-switch";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { BaseMemberProps } from "@custom-types/member-types";
import { Box } from "@mui/material";
import { useAppParams } from "@router/router-helper";
import { APITypes } from "@stellar/api-logic";
import { setMemberPermissionsInCompany } from "@store/members/members-slice-thunk";
import { useAppDispatch } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import {
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TEXT_FONT_SIZE,
} from "@styles/common-styles";
import { UserProfile } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { hasCreateGroupsPermission } from "@utils/permission-utils";
import { useState } from "react";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { PartialNull } from "@custom-types/types";

/**
 * Component that shows a toggle to assign the permission to create groups to a group manager.
 * The component's visibility is not controlled by the component itself, but by the parent component,
 * which decides whether to show it or not.
 * This component disables the toggle if the current user does not have the permission to assign
 * permissions to other users.
 */
export function CreateGroupsPermissionToggle({
  member,
}: PartialNull<BaseMemberProps>): JSX.Element {
  const [canCreateGroups, setCanCreateGroups] = useState<boolean>(
    hasCreateGroupsPermission({ member })
  );
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();

  // Only certain users (EA) can edit other users' permissions.
  // We leave it visible for other users that can read the info but disabled.
  const { canEditMember } = useHasUserValidRoleCompanyLevel();

  const [isChangingPermission, setIsChangingPermission] =
    useState<boolean>(false);

  /**
   * Changes the permission for the member to create groups.
   *
   * @param isChecked Flag indicating the status of the toggle, whether it is checked or not.
   */
  async function onChangedCanAssignCreateGroupsPermission(
    isChecked: boolean
  ): Promise<void> {
    trackEvent({
      name: UserProfile.updatePermissions,
      props: {
        permission: APITypes.UserPermissionName.CREATE_GROUP,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- API name.
        value: isChecked,
        source: "user-info",
      },
    });
    setCanCreateGroups(isChecked);
    setIsChangingPermission(true);
    try {
      if (!member) {
        throw new Error("Member is not provided");
      }
      if (!companyId) {
        throw new Error("Workspace is not provided");
      }
      await dispatch(
        setMemberPermissionsInCompany({
          coreApiClient,
          userIdentity: member.identity,
          companyId,
          permissions: [
            {
              name: APITypes.UserPermissionName.CREATE_GROUP,
              // eslint-disable-next-line @typescript-eslint/naming-convention -- API name.
              allowed: isChecked,
            },
          ],
        })
      );
    } catch (error) {
      // If there is an error, we need to revert the toggle to the previous state.
      setCanCreateGroups(!isChecked);
      const action = isChecked ? "give" : "remove";
      handleErrorWithToast({
        id: `setPermissions-${Date.now().toString()}`,
        title: `Cannot ${action} permission to member to create groups.`,
        error,
      });
    } finally {
      setIsChangingPermission(false);
    }
  }

  return (
    <>
      <Box
        component="div"
        sx={{
          color: sphereColors.gray600,
          fontSize: DEFAULT_TEXT_FONT_SIZE,
        }}
      >
        Permissions
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          component="div"
          sx={{
            color: sphereColors.gray800,
            fontSize: DEFAULT_INPUT_FONT_SIZE,
          }}
        >
          Group Creation
        </Box>
        <Box
          component="div"
          sx={{ color: sphereColors.gray600, fontSize: DEFAULT_TEXT_FONT_SIZE }}
        >
          <FaroSwitch
            disabled={!canEditMember || isChangingPermission}
            checked={canCreateGroups}
            onChange={(event, isChecked) => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
              onChangedCanAssignCreateGroupsPermission(isChecked);
            }}
          />
        </Box>
      </Box>
    </>
  );
}
