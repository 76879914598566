import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { BaseMemberAndTeam } from "@custom-types/member-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { CoreApiWithCompanyIdProps } from "@store/store-types";
import {
  FetchCompanyMemberDetailsProps,
  RemoveMemberProps,
  UpdateMemberRoleProps,
  UpdateMemberRoleResult,
} from "@src/store/members/members-slice-helper";
import { RootState } from "@store/store-helper";

/** Fetches company members from the backend so they can be put into the store */
export const fetchCompanyMembers = createAsyncThunk<
  SphereDashboardAPITypes.ICompanyMemberBase[],
  CoreApiWithCompanyIdProps,
  { state: RootState }
>(
  "members/fetchCompanyMembers",
  async ({ coreApiClient, companyId }) => {
    if (!companyId) {
      throw new Error("No companyId was given to fetchCompanyMembers");
    }
    try {
      /**
       * Note: Currently, we are fetching all users from the endpoint, including user groups.
       * Once the backend updates the teams endpoint with roles,
       * we can remove user groups from this fetch to optimize the request.
       */
      return await coreApiClient.V3.SDB.getMembersInCompany(
        companyId,
        ["user", "user-group", "user-invite"]
      );
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (arg, api) => {
      // Skip fetching if members have already been fetched
      return !api.getState().members.fetched.hasFetchedWorkspaceMembers;
    },
  }
);

/** Fetches the details of a company member so it can be put into the members store */
export const fetchCompanyMemberDetails = createAsyncThunk<
  SphereDashboardAPITypes.ICompanyMemberDetails,
  FetchCompanyMemberDetailsProps
>(
  "members/fetchCompanyMemberDetails",
  async ({ coreApiClient, companyId, identity }) => {
    try {
      const data = await coreApiClient.V3.SDB.getMemberDetailsInCompany(
        companyId,
        identity
      );

      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Remove a member from the company using the backend and then removes it from the store as well */
export const removeMemberFromCompany = createAsyncThunk<
  BaseMemberAndTeam,
  RemoveMemberProps
>(
  "members/removeCompanyMember",
  async ({ coreApiClient, companyId, member }) => {
    if (!companyId) {
      throw new Error(
        "No companyId was given to remove member from workspace!"
      );
    }

    if (!member?.email) {
      throw new Error("No email was given to remove member from workspace!");
    }

    try {
      await coreApiClient.V3.SDB.removeMemberFromCompany(
        companyId,
        member.email
      );
      return member;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Update a member role in the workspace using the backend,
 * and then update it in the store as well
 */
export const updateMemberRoleInWorkspace = createAsyncThunk<
  UpdateMemberRoleResult,
  UpdateMemberRoleProps
>(
  "members/updateMemberRoleInWorkspace",
  async ({ coreApiClient, companyId, role, identity }) => {
    if (!companyId) {
      throw new Error("No companyId was given to update member role!");
    }

    const memberData = {
      role: role,
      identity: identity,
    };

    try {
      const result = await coreApiClient.V3.SDB.updateCompanyMember(
        companyId,
        identity,
        memberData
      );

      return {
        role: result.role,
        identity: result.identity,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface SetMemberPermissionsInCompanyPayload
  extends SphereDashboardAPITypes.ISetMemberPermissionsInCompanyPayload,
    CoreApiWithCompanyIdProps {
  userIdentity: APITypes.UserIdentity;
}

/**
 * Set the permissions of a member in the company using the backend.
 */
export const setMemberPermissionsInCompany = createAsyncThunk<
  SphereDashboardAPITypes.SetMemberPermissionsInCompanyResponse,
  SetMemberPermissionsInCompanyPayload
>(
  "members/setMemberPermissionsInCompany",
  async ({ coreApiClient, companyId, userIdentity, permissions }) => {
    try {
      if (!companyId) {
        throw new Error("No companyId was given to update member permissions");
      }

      const result = await coreApiClient.V3.SDB.setMemberPermissionsInCompany(
        companyId,
        userIdentity,
        { permissions }
      );
      return result;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);
