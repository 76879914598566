import { ApiClient, CoreAPITypes, CoreAPIUtils, SphereDashboardAPITypes } from "@stellar/api-logic";
import { exponentialBackOff, retry } from "@faro-lotv/foundation";
import { IntegrationCompany, IntegrationProject } from "@services/integrations-service/integrations-service-types";

interface Props {
  /** Core API client instance */
  coreApiClient: ApiClient;

  /** URL of the integration API */
  integrationApiUrl: string;

  /** Callback to re-authorize the integration */
  reauthorize: (integrationId: SphereDashboardAPITypes.IntegrationId) => Promise<void>;
}

/** Abstract class to manage a single 3rd party integration */
export abstract class BaseService {
  #coreApiClient: ApiClient;
  #integrationApiUrl: string;
  #reauthorize: (integrationId: SphereDashboardAPITypes.IntegrationId) => Promise<void>;
  protected abstract integrationId: SphereDashboardAPITypes.IntegrationId;

  constructor({
    coreApiClient,
    integrationApiUrl,
    reauthorize,
  }: Props) {
    this.#coreApiClient = coreApiClient;
    this.#integrationApiUrl = integrationApiUrl;
    this.#reauthorize = reauthorize;
  }

  /**
   * @returns the IntegrationCompany entities of the user
   * @throws {Error} if it fails to get the entities
   */
  protected abstract getIntegrationCompanies(): Promise<IntegrationCompany[]>;

  /**
   * @returns the IntegrationProject entities for a given integration company
   * @throws {Error} if it fails to get the entities
   * @param companyId ID of the company
   */
  protected abstract getIntegrationProjects(companyId: string): Promise<IntegrationProject[]>;

  /**
   * Handles errors specific to this integration service.
   * @param error Error to handle
   */
  protected abstract handleIntegrationError(error: CoreAPITypes.IBaseResponse): Promise<void>;

  /**
   * Issues a request to the integration API
   * If the request fails it attempts to handle common and integration specific errors before
   * attempting to issue the request again.
   * @returns the response of the issued request
   * @throws {Error} if the request fails and the error could not be handled
   * @param url of the endpoint to issue the request
   * @param verb the request method
   * @param headers optional request headers to add
   */
  protected async request<T>({
    url,
    verb,
    headers,
  }: SphereDashboardAPITypes.ProxyRequestPayload): Promise<T> {
    const proxyRequest = (): Promise<T> => {
      return this.#coreApiClient.V2.SDB.proxyRequest<T>(
        this.integrationId,
        {
          url: `${this.#integrationApiUrl}/${url}`,
          verb,
          headers,
        }
      );
    };

    try {
      return await retry(
        proxyRequest,
        {
          max: 3,
          delay: exponentialBackOff,
        }
      );
    } catch (error) {
      await this.handleError(error);
      return await retry(
        proxyRequest,
        {
          max: 3,
          delay: exponentialBackOff,
        }
      );
    }
  }

  /**
   * It first attempts to refresh the token. If the token refresh fails it requests
   * the user to re-authorize the integration.
  */
  public async handleTokenUpdate(): Promise<void> {
    try {
      await this.refreshIntegrationToken();
    } catch (_) {
      await this.#reauthorize(this.integrationId);
    }
  }

  /**
   * Handles errors originating from the CoreAPI proxy backend for requests issued to the Procore backend.
   * - Validates that the error has the type of a CoreAPI error. If not it throws the error as it is.
   * - Handles errors that are specific to the 3rd party integration.
   * @param error Error to handle
   */
  private async handleError(error: unknown): Promise<void> {
    if (!CoreAPIUtils.isBaseResponse(error)) {
      throw error;
    }

    await this.handleIntegrationError(error);
  }

  /**
   * Issues a request to refresh the integration token
   * @returns the refreshed integration token
   * @throws {CoreAPITypes.IResponseError} if it fails to refresh the integration token
   */
  private async refreshIntegrationToken(): Promise<SphereDashboardAPITypes.IntegrationToken> {
    return await this.#coreApiClient.V1.SDB.refreshIntegrationToken(this.integrationId);
  }
}
