import { ProjectApi } from "@api/project-api/project-api";
import { ApiClient, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  IntegrationCompany,
  IntegrationProject,
  ProjectIntegrationId,
  ProjectIntegrationsMap,
} from "@services/integrations-service/integrations-service-types";
import { isProjectIntegrations } from "@src/services/integrations-service/integrations-type-guards";
import { createMutationSetElementMetaData } from "@faro-lotv/service-wires";
import { ProcoreService } from "@services/integrations-service/procore/procore-service";
import { AutodeskService } from "@services/integrations-service/autodesk/autodesk-service";
import { JSONValue } from "@faro-lotv/foundation";

interface Props {
  /** Core API client instance */
  coreApiClient: ApiClient;

  /** Project API client instance */
  projectApiClient: ProjectApi;

  /** URL of the Procore API */
  procoreApiUrl: string;

  /** Callback to re-authorize the integration */
  reauthorize: (integrationId: SphereDashboardAPITypes.IntegrationId) => Promise<void>;
}

/** Service that provides methods to manage 3rd party integrations with a project */
export class IntegrationsService {
  #projectApiClient: ProjectApi;
  #procoreService: ProcoreService;
  #autodeskService: AutodeskService;

  constructor({
    coreApiClient,
    projectApiClient,
    procoreApiUrl,
    reauthorize,
  }: Props) {
    this.#projectApiClient = projectApiClient;
    this.#procoreService = new ProcoreService({
      coreApiClient,
      integrationApiUrl: procoreApiUrl,
      reauthorize,
    });
    this.#autodeskService = new AutodeskService({
      coreApiClient,
      integrationApiUrl: "https://developer.api.autodesk.com/project/v1",
      reauthorize,
    });
  }

  /**
   * @returns The ProjectIntegrations entity of the current project
   * @throws {ProjectApiError} if it fails to fetch data from Project Api
   */
  public async getProjectIntegrations(): Promise<
    ProjectIntegrationsMap | undefined
  > {
    const root = await this.#projectApiClient.getRootIElement();
    const projectIntegrations = root.metaDataMap?.projectIntegrations;

    if (!projectIntegrations) {
      return;
    }

    if (!isProjectIntegrations(projectIntegrations)) {
      throw Error("Failed to validate the project integrations object");
    }

    return projectIntegrations;
  }

  /**
   * @returns the companies of an integration
   * @throws {Error} if it fails to get the companies
   * @param integrationId ID of the integration
   */
  public async getIntegrationCompanies(
    integrationId: SphereDashboardAPITypes.IntegrationId
  ): Promise<IntegrationCompany[]> {
    switch (integrationId) {
      case SphereDashboardAPITypes.IntegrationId.procore:
        return this.#procoreService.getIntegrationCompanies();
      case SphereDashboardAPITypes.IntegrationId.autodesk:
        return this.#autodeskService.getIntegrationCompanies();
    }
  }

  /**
   * @returns projects for a given company of an integration
   * @throws {Error} if it fails to get the projects
   * @param integrationCompany Integration company entity
   */
  public async getIntegrationProjects(
    integrationCompany: IntegrationCompany
  ): Promise<IntegrationProject[]> {
    switch (integrationCompany.integrationId) {
      case SphereDashboardAPITypes.IntegrationId.procore:
        return this.#procoreService.getIntegrationProjects(integrationCompany.id);
      case SphereDashboardAPITypes.IntegrationId.autodesk:
        return this.#autodeskService.getIntegrationProjects(integrationCompany.id);
    }
  }

  /**
   * Connects the XG project to an integration project.
   * @returns The updated ProjectIntegrations entity
   */
  public async connectProject({
    integrationId,
    id,
    name,
    companyId,
    issuesOrObservationsBcfProjectId,
    rfisBcfProjectId,
    type,
  }: IntegrationProject): Promise<ProjectIntegrationsMap> {
    const projectIntegrations: ProjectIntegrationsMap  = await this.getProjectIntegrations() ?? {};

    switch (integrationId) {
      case SphereDashboardAPITypes.IntegrationId.procore:
        projectIntegrations[ProjectIntegrationId.procore] = {
          projectName: name,
          projectId: id,
          companyId: companyId,
          providerId: SphereDashboardAPITypes.IntegrationId.procore,
        };
        projectIntegrations[ProjectIntegrationId.procoreObservations] = {
          bcfProjectId: issuesOrObservationsBcfProjectId,
        };
        projectIntegrations[ProjectIntegrationId.procoreRfis] = {
          bcfProjectId: rfisBcfProjectId,
        };
        break;
      case SphereDashboardAPITypes.IntegrationId.autodesk:
        projectIntegrations[ProjectIntegrationId.autodesk] = {
          projectName: name,
          projectId: id,
          hubId: companyId,
          providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
        };

        projectIntegrations[ProjectIntegrationId.autodeskAccIssues] = type === "ACC"
        ? {
          bcfProjectId: issuesOrObservationsBcfProjectId,
        }
        : null;

        projectIntegrations[ProjectIntegrationId.autodeskAccRfis] = type === "ACC"
        ? {
          bcfProjectId: rfisBcfProjectId,
        }
        : null;

        projectIntegrations[ProjectIntegrationId.autodeskBim360Issues] = type === "BIM360"
        ? {
          bcfProjectId: issuesOrObservationsBcfProjectId,
        } : null;

        projectIntegrations[ProjectIntegrationId.autodeskBim360Rfis] = type === "BIM360"
        ? {
          bcfProjectId: rfisBcfProjectId,
        } : null;
        break;
    }

    return await this.setProjectIntegrations(projectIntegrations);
  }

  /**
   * Disconnects an integration from a project.
   * To achieve this it sets each project integration value to null for each user level integration
   * @returns The updated ProjectIntegrations entity
   * @param integrationId ID of the integration to disconnect
   * @throws {Error} if the Project Api client is not defined
   * @throws {ProjectApiError} if it fails to update or fetch data from Project Api
   */
  public async disconnectProject(
    integrationId: SphereDashboardAPITypes.IntegrationId
  ): Promise<ProjectIntegrationsMap | undefined> {
    const projectIntegrations = await this.getProjectIntegrations();

    if (!projectIntegrations) {
      return;
    }

    switch (integrationId) {
      case SphereDashboardAPITypes.IntegrationId.procore:
        projectIntegrations[ProjectIntegrationId.procore] = null;
        projectIntegrations[ProjectIntegrationId.procoreObservations] = null;
        projectIntegrations[ProjectIntegrationId.procoreRfis] = null;
        break;
      case SphereDashboardAPITypes.IntegrationId.autodesk:
        projectIntegrations[ProjectIntegrationId.autodesk] = null;
        projectIntegrations[ProjectIntegrationId.autodeskAccIssues] = null;
        projectIntegrations[ProjectIntegrationId.autodeskAccRfis] = null;
        projectIntegrations[ProjectIntegrationId.autodeskBim360Issues] = null;
        projectIntegrations[ProjectIntegrationId.autodeskBim360Rfis] = null;
        break;
    }

    return await this.setProjectIntegrations(projectIntegrations);
  }

  /**
   * Stores the ProjectIntegrations entity in the metadata of the ProjectAPI root IElement
   * @param projectIntegrations ProjectIntegrations entity
   * @returns the updated ProjectIntegrations entity
   * @throws {Error} if the Project Api client is not defined
   * @throws {ProjectApiError} if it fails to update or fetch data from Project Api
   */
  private async setProjectIntegrations(
    projectIntegrations: ProjectIntegrationsMap
  ): Promise<ProjectIntegrationsMap> {
    if (!this.#projectApiClient) {
      throw Error("ProjectApi client is not defined");
    }

    const { id } = await this.#projectApiClient.getRootIElement();

    const mutation = createMutationSetElementMetaData(id, [
      {
        key: "ProjectIntegrations",
        value: projectIntegrations as JSONValue,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by Project Api backend
        skipIfPresent: false,
      },
    ]);

    await this.#projectApiClient.applyMutations([mutation]);
    const updatedProjectIntegrations = await this.getProjectIntegrations();

    if (!updatedProjectIntegrations) {
      throw Error(
        "Integrations were updated for the project but they were later not available in the backend."
      );
    }

    return updatedProjectIntegrations;
  }
}
