import { MembersTable } from "@components/table/members/members-table";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { BaseGroupDetailsProps } from "@custom-types/group-types";
import { fetchingGroupsFlagsSelector } from "@store/groups/groups-selector";
import { useAppSelector } from "@store/store-helper";
import { GroupMemberBulkActions } from "@pages/group-details/group-member-bulk-actions";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";

interface Props extends Partial<BaseGroupDetailsProps>, BaseCompanyIdProps {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Contains the Managers table of the group details
 */
export function GroupManagersTable({
  group,
  companyId,
  isLoading = false,
}: Props): JSX.Element {
  const { isFetchingGroups } = useAppSelector(fetchingGroupsFlagsSelector);

  const buttonComponents = GroupMemberBulkActions({
    companyId,
    group,
  });

  return (
    <MembersTable
      companyId={companyId}
      members={group?.members ?? []}
      tableSubject={"group"}
      isLoading={isFetchingGroups || isLoading}
      group={group}
      requiredColumns={[
        MemberHeaders.avatar,
        MemberHeaders.name,
        MemberHeaders.email,
        MemberHeaders.status,
        MemberHeaders.role,
        MemberHeaders.joinedDate,
        MemberHeaders.options,
      ]}
      bulkActionButtons={buttonComponents}
    />
  );
}
