import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { Divider, Grid, SvgIcon, Typography } from "@mui/material";
import {
  IntegrationDialogProps,
  IntegrationSections,
} from "@pages/integrations/integrations-types";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import CheckRectangular from "@assets/icons/new/check_rectangular_l18.svg?react";
import TabsIntegrations from "@assets/icons/new/tabs_integration.svg?react";

/** Shows dialog for successfully connecting to an integration */
export function IntegrationsSuccessfulDialog({
  dialogConfirmCallback,
  selectedIntegrationId,
}: IntegrationDialogProps): JSX.Element {
  return (
    <FaroDialog
      open={true}
      onConfirm={dialogConfirmCallback}
      confirmText="Got it"
      title=""
      isSuccessMessage={true}
      fullWidth={true}
      dialogActionSx={{ justifyContent: "center" }}
    >
      <Grid
        maxWidth="100%"
        alignItems="center"
        container
        flexDirection="column"
        sx={{
          paddingX: "28px",
        }}
      >
        <Typography
          sx={{
            fontSize: "32px",
          }}
        >
          Integration enabled
        </Typography>
        <Typography
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            marginBottom: "40px",
          }}
        >
          {`Add ${IntegrationSections[selectedIntegrationId].displayName} to your project whenever you want`}
        </Typography>
        <SvgIcon
          inheritViewBox={true}
          component={CheckRectangular}
          sx={{
            width: "95px",
            height: "95px",
          }}
        />

        <Divider
          sx={{
            marginY: "40px",
            width: "100%",
          }}
        />

        <Typography
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            marginTop: "0px",
            marginBottom: "40px",
            textAlign: "center",
          }}
        >
          To use the integration you need to connect it to a Sphere XG project. To do
          this, go to the "Integrations" tab in the project details page.
        </Typography>
        <SvgIcon
          inheritViewBox={true}
          component={TabsIntegrations}
          sx={{ width: "450px", height: "72px" }}
        />
      </Grid>
    </FaroDialog>
  );
}
