import { useCallback } from "react";
import { getLsDataV2Package } from "@api/stagingarea-api/stagingarea-api";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useUploadMultipleScans } from "@hooks/data-management/use-upload-multiple-scans";
import { isValidFile } from "@hooks/file-upload-utils";
import { createRevisionForElsScans } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import {
  ALLOWED_EXTENSIONS_ALL,
  filesInfoForTracking,
  getScansAlreadyUploaded,
  isGLS,
  isValidGlsFile,
  MAX_FILE_SIZE_IN_MB,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { getFilesWithDuplicateNames, sortFiles } from "@utils/file-utils";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useUploadErrorToast, UploadErrorToastType } from "@hooks/data-management/use-upload-error-toast";
import { useReadLsDataV2 } from "@hooks/data-management/use-read-ls-data-v2";
import { SdbProject } from "@custom-types/project-types";
import { APITypes } from "@stellar/api-logic";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";

type ReturnFunction = (
  selectedFiles: FileList | File[],
  /** A map of the externalIds of the uploaded ELS scans, as returned by getUploadedIdsMap. */
  uploadedIdsMap: { [key: APITypes.UUID]: boolean },
) => Promise<boolean>;

/**
 * Set the files for upload, replacing any previous selected ones. We allow to upload a single ELS folder at once.
 * Returns true on success, false if some kind of error happened. In that case, an error toast is displayed
 * automatically.
 */
export function useOnSelectFiles(project: SdbProject): ReturnFunction {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const uploadErrorToast = useUploadErrorToast();
  const projectApiClient = useProjectApiClient({ projectId: project.id });

  const uploadMultipleScans = useUploadMultipleScans(project);
  const readLsDataV2 = useReadLsDataV2(project.id);

  const allowedExtensions = ALLOWED_EXTENSIONS_ALL;

  return useCallback(
    async (
      selectedFiles: FileList | File[],
      uploadedIdsMap: { [key: APITypes.UUID]: boolean }
    ): Promise<boolean> => {
      // We want to track the action of selecting the files, so it's better to track before any validation.
      // If/when the upload actually starts, we track another event: DataManagementEvents.startUpload.
      trackEvent({
        name: DataManagementEvents.selectFiles,
        props: filesInfoForTracking(selectedFiles ? [...selectedFiles] : []),
      });

      // Early return if no files were selected.
      if (!selectedFiles || selectedFiles.length === 0) {
        uploadErrorToast(UploadErrorToastType.noFiles);
        return false;
      }

      const allowedFiles: File[] = [];
      for (const file of selectedFiles) {
        const result = isValidFile({ file, allowedExtensions, maxFileSize: MAX_FILE_SIZE_IN_MB });
        if (result.isValid) {
          allowedFiles.push(file);
        }
      }
      if (allowedFiles.length === 0) {
        uploadErrorToast(UploadErrorToastType.noFiles);
        return false;
      }

      const glsFiles = allowedFiles.filter((file) => isGLS(file.name));
      if (glsFiles.length === 0) {
        uploadErrorToast(UploadErrorToastType.noFiles);
        return false;
      }
      const lsDataV2Files = getLsDataV2Package(allowedFiles);
      if (!lsDataV2Files?.isValid) {
        uploadErrorToast(UploadErrorToastType.invalidFormat);
        return false;
      }
      const lsDataV2 = await readLsDataV2(lsDataV2Files);
      if (!lsDataV2) {
        uploadErrorToast(UploadErrorToastType.invalidFormat);
        return false;
      }

      const hasInvalidFile = glsFiles.some((file) => !isValidGlsFile(file, lsDataV2));
      if (hasInvalidFile) {
        uploadErrorToast(UploadErrorToastType.invalidFormat);
        return false;
      }

      const filesDuplicate = getFilesWithDuplicateNames(glsFiles);
      if (0 < filesDuplicate.size) {
        uploadErrorToast(UploadErrorToastType.invalidFormat);
        return false;
      }

      const scansAlreadyUploaded = getScansAlreadyUploaded(glsFiles, lsDataV2, uploadedIdsMap);
      // If all scans were already uploaded before, we don't have anything to do.
      const hasOnlyExistingScans = glsFiles.every((file) => scansAlreadyUploaded.has(file));
      if (hasOnlyExistingScans) {
        uploadErrorToast(UploadErrorToastType.noNewFiles);
        return false;
      }

      const glsFilesSorted = sortFiles(glsFiles);
      const glsFilesToUpload = glsFilesSorted.filter((file) => !scansAlreadyUploaded.has(file));

      try {
        const {
          registrationRevisionId, captureTreeRootAndClustersByUuid, captureTreeMaps,
        } = await createRevisionForElsScans(
          projectApiClient, lsDataV2, glsFilesToUpload
        );
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- We don't await this background process.
        uploadMultipleScans(registrationRevisionId, captureTreeRootAndClustersByUuid,
          /* captureTreeScanIdByUuid */ captureTreeMaps.scanIdByUuid,
          glsFilesSorted, lsDataV2, scansAlreadyUploaded);
      } catch (error) {
        handleErrorWithToast({
          id: `createRevisionForElsScans-${Date.now().toString()}`,
          title: "Failed to prepare a revision to import data. Please try again.",
          error,
        });
      }
      return true;
    },
    [
      readLsDataV2,
      trackEvent,
      allowedExtensions,
      uploadMultipleScans,
      handleErrorWithToast,
      uploadErrorToast,
      projectApiClient,
    ]
  );
}
