import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationsInProgressDialog } from "@pages/integrations/integrations-dialog/integrations-in-progress-dialog";
import { IntegrationsDisconnectDialog } from "@pages/integrations/integrations-dialog/integrations-disconnect-dialog";
import { IntegrationsSuccessfulDialog } from "@pages/integrations/integrations-dialog/integrations-successful-dialog";
import {
  IntegrationDialogCallbacks,
  IntegrationDialogTypes,
} from "@pages/integrations/integrations-types";

interface Props extends IntegrationDialogCallbacks {
  /** Selected integration ID */
  selectedIntegrationId: SphereDashboardAPITypes.IntegrationId;

  /** The selected dialog */
  selectedDialog: IntegrationDialogTypes;
}

/** All the dialogs for integrations process */
export function IntegrationDialog({
  selectedIntegrationId,
  selectedDialog,
  onSuccessDialogConfirm,
  onInProgressDialogCancel,
  onDisconnectDialogConfirm,
  onDisconnectDialogCancel,
}: Props): JSX.Element {
  switch (selectedDialog) {
    case "inProgress":
      return (
        <IntegrationsInProgressDialog
          selectedIntegrationId={selectedIntegrationId}
          dialogCancelCallback={onInProgressDialogCancel}
        />
      );

    case "disconnect":
      return (
        <IntegrationsDisconnectDialog
          dialogConfirmCallback={onDisconnectDialogConfirm}
          dialogCancelCallback={onDisconnectDialogCancel}
        />
      );

    case "success":
      return (
        <IntegrationsSuccessfulDialog
          selectedIntegrationId={selectedIntegrationId}
          dialogConfirmCallback={onSuccessDialogConfirm}
        />
      );
  }
}
