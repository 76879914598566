import {
  MemberBulkButtonComponents,
  MemberTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { useAppSelector } from "@store/store-helper";
import { selectedRowIdsSelector } from "@store/table/table-selector";
import { BulkMemberCsvExport } from "@components/table/members/bulk-actions/bulk-member-csv-export";
import { BaseGroupDetailsProps } from "@custom-types/group-types";
import { BulkSendEmail } from "@components/table/members/bulk-actions/bulk-send-email";
import { BulkResendInvitation } from "@components/table/members/bulk-actions/bulk-resend-invitation";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { BulkRemoveMember } from "@components/table/members/bulk-actions/bulk-remove-member";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useToast } from "@hooks/use-toast";
import { BulkChangeGroupRole } from "@components/table/members/bulk-actions/bulk-change-group-role";
import { isMemberActive } from "@utils/user-utils";
import { useMemo } from "react";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { useHasUserValidRoleGroupLevel } from "@hooks/access-control/use-has-user-valid-role-group-level";
import { isTeam } from "@utils/team-utils";

/** List all the bulk action components for group member table */
export function GroupMemberBulkActions({
  companyId,
  group,
}: Partial<BaseGroupDetailsProps> &
  BaseCompanyIdProps): MemberBulkButtonComponents {
  const selectedRowIds = useAppSelector(selectedRowIdsSelector);

  const { navigateToGroups } = useAppNavigation();
  const { showToast } = useToast();
  const { canEditMember, canDeleteMemberFromGroup } =
    useHasUserValidRoleGroupLevel({
      selectedGroup: group ?? null,
    });

  const groupMembers = group?.members ?? [];
  const groupName = group?.name ?? "";
  const groupId = group?.id ?? "";

  const filteredGroupMembers = groupMembers.filter((member) =>
    selectedRowIds.some((identity) => identity === member.identity)
  );

  const isAnyRowSelected = filteredGroupMembers.length !== 0;
  const isAnyMemberPending = filteredGroupMembers.some(
    (member) => !isMemberActive(member)
  );
  const isAnyMemberTeam = filteredGroupMembers.some((member) => isTeam(member));

  const allowedButtonsBasedOnRowSelection: MemberTableBulkName[] =
    useMemo(() => {
      const allowedButtons: MemberTableBulkName[] = ["exportCsv"];

      if (isAnyRowSelected && group) {
        if (!isAnyMemberTeam) {
          allowedButtons.push("sendEmail");
        }

        if (canEditMember) {
          allowedButtons.push("changeRole");
        }

        if (isAnyMemberPending) {
          allowedButtons.push("resendInvitation");
        }

        if (canDeleteMemberFromGroup) {
          allowedButtons.push("removeMember");
        }
      }

      return allowedButtons;
    }, [
      canDeleteMemberFromGroup,
      canEditMember,
      group,
      isAnyMemberPending,
      isAnyMemberTeam,
      isAnyRowSelected,
    ]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  /**
   * Navigates to the groups page after the group has been removed by removing all the members of it
   * and notifies the user.
   */
  function onGroupRemoved(): void {
    navigateToGroups({ companyId });
    showToast({
      message: (
        <>
          Successfully removed group <var>{groupName}</var>
        </>
      ),
      type: "success",
    });
  }

  // Early exit if the group is not yet loaded
  if (!groupMembers.length) {
    return {
      changeRole: null,
      resendInvitation: null,
      removeMember: null,
      sendEmail: null,
      exportCsv: null,
    };
  }

  return {
    changeRole: shouldShowBulkButtons("changeRole") ? (
      <BulkChangeGroupRole companyId={companyId} groupId={groupId} />
    ) : null,

    resendInvitation: shouldShowBulkButtons("resendInvitation") ? (
      <BulkResendInvitation
        selectedEntities={filteredGroupMembers}
        companyId={companyId}
        subjectType="group"
      />
    ) : null,

    removeMember: shouldShowBulkButtons("removeMember") ? (
      <BulkRemoveMember
        subjectType="group"
        companyId={companyId}
        groupId={groupId}
        onGroupRemoved={onGroupRemoved}
      />
    ) : null,

    sendEmail: shouldShowBulkButtons("sendEmail") ? (
      <BulkSendEmail selectedEntities={filteredGroupMembers} />
    ) : null,

    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <BulkMemberCsvExport
        selectedEntities={
          isAnyRowSelected ? filteredGroupMembers : groupMembers
        }
        subjectType="group"
        entityName={groupName}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
