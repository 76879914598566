import { SdbProject } from "@custom-types/project-types";
import { hasUserValidPermissionProjectLevel } from "@utils/permission-control/project-permission-control";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";

/** Checks if the current user is allowed to change the project manager and group */
export function isAllowedToChangeManagement(
  isProjectArchived: boolean,
  isDemoProject: boolean,
  selectedProject: SdbProject | null
): boolean {
  if (isProjectArchived || isDemoProject) {
    return false;
  }

  return hasUserValidPermissionProjectLevel({
    selectedProject,
    permissionName: RequiredPermissionProjectLevelName.canChangeProjectGroup,
  });
}
