import { UserInfo, userInfoWidth } from "@components/user-info/user-info";
import { Box, Grid } from "@mui/material";
import { MemberProfileSkeleton } from "@components/common/skeleton-loading/member-profile-skeleton";
import { BaseMemberProps } from "@custom-types/member-types";
import { useMemberGroups } from "@hooks/use-member-groups";
import { NotFoundPage } from "@pages/not-found-page";
import { isCompanyMemberDetails } from "@custom-types/type-guards";
import { MemberGroupsTable } from "@components/common/member/member-groups-table";
import { MemberProfileEmptyPage } from "@components/common/empty-page/member-profile-empty-page";
import { PartialNull } from "@custom-types/types";
import { FetchingStatus } from "@store/store-types";
import { TeamDetailsInfoBar } from "@pages/members/teams/team-details/team-details-info-bar";
import { useAppSelector } from "@store/store-helper";
import { fetchingTeamsFlagsSelector } from "@store/teams/teams-selector";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";

interface Props extends PartialNull<BaseMemberProps> {
  /** Flag to indicate if the content of the member is loading */
  isLoading: boolean;

  /** Validate if we are opening a team overview or a member overview */
  subjectType: "team" | "member";
}

/**
 * Page to show the overview of the profile of the selected user.
 */
export function MemberOverviewPage({
  member,
  isLoading,
  subjectType,
}: Props): JSX.Element {
  const { groups, companyMemberGroupsStatus } = useMemberGroups();
  const { isFetchingTeamMembers } = useAppSelector(fetchingTeamsFlagsSelector);

  const isFetching =
    isLoading || companyMemberGroupsStatus === FetchingStatus.pending;

  if (isFetching || !groups) {
    return <MemberProfileSkeleton />;
  }

  // Show not found page if fetching member or groups failed
  // or if the member entity is not of type ICompanyMemberDetails
  if (
    !member ||
    !isCompanyMemberDetails(member) ||
    companyMemberGroupsStatus !== FetchingStatus.succeeded
  ) {
    return <NotFoundPage />;
  }

  let emptyPage;

  if (groups.length === 0) {
    switch (subjectType) {
      case "member":
        emptyPage = <MemberProfileEmptyPage />;
        break;
      case "team":
        emptyPage = (
          <EmptyPage
            title="No access granted"
            subtitle={`This ${TEAM_DISPLAY_NAME} hasn't been assigned to any groups or projects within the workspace`}
            icon={NoDataIcon}
          />
        );
        break;
      default:
        emptyPage = (
          <EmptyPage
            title="Unexpected subject type"
            subtitle="Please contact support to resolve this issue."
            icon={NoDataIcon}
          />
        );
        break;
    }
  } else {
    emptyPage = <MemberGroupsTable groups={groups} member={member} />;
  }

  return (
    <Grid data-testid="MEMBER_PROFILE_OVERVIEW_CONTAINER">
      <TeamDetailsInfoBar
        isLoading={isFetchingTeamMembers === FetchingStatus.pending}
      />

      {/* Top info bar */}

      {/* Container for the projects, groups and user info component */}
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          width: "100%",
          marginTop: subjectType === "member" ? "14px" : "0",
        }}
      >
        {/* Container for the projects and groups */}
        <Box
          component="div"
          sx={{
            width: {
              // Needed to grow the container to the remaining space
              flexGrow: 1,
              // On Md devices the container appears at the left of the page and needs a width to share with user info.
              md: `calc(100% - ${userInfoWidth})`,
            },
            // On XS devices the container appears at the bottom of the page and needs a margin top
            marginTop: {
              xs: "45px",
              md: "0px",
            },
            // On Md devices the container appears at the left of the page and needs a margin right
            marginRight: {
              xs: "0px",
              md: "20px",
            },
          }}
        >
          {emptyPage}
        </Box>

        {/* Container for the user info */}
        {subjectType === "member" && (
          <Box
            component="div"
            sx={{
              display: "flex",
              width: {
                // On XS devices the container uses the full width of the page but centers the content
                xs: "100%",
                md: userInfoWidth,
              },
              justifyContent: "center",
            }}
          >
            {/* Container for the user info to limit its size and justify it in the middle */}
            <Box
              component="div"
              sx={{
                display: "flex",
                // The width remains the same on all devices
                width: userInfoWidth,
              }}
            >
              <UserInfo member={member} />
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
