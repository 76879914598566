import { getEntityMaps, getIdentityPose, getRootEntity, isClusterEntity, isRootEntity } from "@utils/capture-tree-utils";
import {
  CaptureApiClient,
  CaptureTreeEntity,
  CreateOrUpdateClusterEntityParams,
  CreateOrUpdateRootEntityRequestBody,
  RegistrationState,
  Transformation,
} from "@faro-lotv/service-wires";
import { ProjectApi } from "@api/project-api/project-api";
import { assert, generateGUID, GUID } from "@faro-lotv/foundation";
import { ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import { UUID } from "@stellar/api-logic/dist/api/core-api/api-types";
import { getGlsUuid } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { EntityMaps, RootEntity } from "@custom-types/capture-tree-types";
import { assertValue } from "@utils/assert-utils";

export type CaptureTreeRootAndClustersByUuid = Record<UUID, CreateClusterEntityWithId | CreateRootEntityWithId>;

export interface CreateRevision {
  /** ID of the created registration revision */
  registrationRevisionId: string;
  /**
   * Map from externalId (UUID) from the scan metadata to RootEntity / ClusterEntity.
   * For existing entities, we store the full objects as returned by the API.
   * For new clusters (created in our revision), we store the partial ClusterEntity (= request body for creation).
   */
  captureTreeRootAndClustersByUuid: CaptureTreeRootAndClustersByUuid;
  /** Various maps to efficiently find clusters and scans by their name-path, id or externalId. */
  captureTreeMaps: EntityMaps;
}

interface CreateRootEntityWithId extends CreateOrUpdateRootEntityRequestBody {
  // Make optional ID mandatory.
  id: GUID;
}

interface CreateClusterEntityWithId extends CreateOrUpdateClusterEntityParams {
  // Make optional IDs mandatory.
  id: GUID;
  parentId: GUID;
  name: string;
  pose: Transformation;
}

interface UpdateClusterEntityWithId {
  id: GUID;
}

/**
 * Create the Root Entity in a separate revision, to avoid conflicts with another client creating a Root Entity concurrently.
 * Workaround for: https://faro01.atlassian.net/browse/SMETA-1489
 * @param projectApiClient
 */
async function createRevisionForRootEntity(projectApiClient: ProjectApi): Promise<void> {
  const revision = await projectApiClient.createRegistrationRevision(
    /* captureTreeEntityIds */ [], /* registrationEdgeIds */ [], CaptureApiClient.dashboard
  );
  const registrationRevisionId = revision.id;

  const requestBody: CreateRootEntityWithId = {
    id: generateGUID(),
    pose: getIdentityPose(),
  };
  await projectApiClient.createOrUpdateRootEntityForRegistrationRevision({
    registrationRevisionId,
    requestBody,
  });

  await projectApiClient.updateRegistrationRevision({
    registrationRevisionId,
    state: RegistrationState.registered,
  });

  await projectApiClient.applyRegistrationRevisionToMain(
    registrationRevisionId
  );
}

/**
 * Calculate the changes that we're going to apply in our new revision.
 * [exported for unit tests]
 * @param captureTreeEntities Entities of the main revision.
 * @param mainRevisionRoot RootEntity of the main revision.
 * @param lsDataV2 Metadata from the scanner.
 * @param glsFilesToUpload GLS files to upload, excluding scans that already exist in the Capture Tree.
 * @returns IDs and entities to create or modify, with related information from the Capture Tree and scanner metadata.
 */
export function calculateCaptureTreeChanges(
  captureTreeEntities: CaptureTreeEntity[],
  mainRevisionRoot: RootEntity<CaptureTreeEntity>,
  lsDataV2: ReadLsDataV2Response,
  glsFilesToUpload: File[]
): {
  captureTreeEntityIdsToModify: Set<GUID>;
  captureTreeClusters: (CreateClusterEntityWithId | UpdateClusterEntityWithId)[];
  existingScanIdsForEdges: GUID[];
  captureTreeRootAndClustersByUuid: CaptureTreeRootAndClustersByUuid;
  captureTreeMaps: EntityMaps;
} {
  const captureTreeMaps = getEntityMaps(captureTreeEntities);
  const captureTreeEntityIdsToModify = new Set<GUID>([mainRevisionRoot.id]);
  const captureTreeClusters: (CreateClusterEntityWithId | UpdateClusterEntityWithId)[] = [];

  // Collect root and existing clusters.
  const captureTreeRootAndClustersByUuid: CaptureTreeRootAndClustersByUuid = {
    root: mainRevisionRoot,
    [lsDataV2.rootObject.uuid]: mainRevisionRoot,
  };
  for (const lsCluster of lsDataV2.clusters) {
    const existingClusterId: GUID | undefined = captureTreeMaps.idByPath[lsCluster.treePath];
    const existingCluster = existingClusterId ? captureTreeMaps.entityById[existingClusterId] : undefined;
    if (existingCluster) {
      captureTreeRootAndClustersByUuid[lsCluster.uuid] = existingCluster;
    }
  }

  const newScanUuids = new Set<string>(glsFilesToUpload.map((glsFile) => {
    const uuid = getGlsUuid(glsFile.name, lsDataV2);
    return assertValue(uuid);
  }));

  const existingScanUuidsForEdges = new Set<UUID>();
  for (const edge of lsDataV2.edges) {
    // Which of the two scans is/are being uploaded?
    const isSourceNew = newScanUuids.has(edge.sourceUuid);
    const isTargetNew = newScanUuids.has(edge.targetUuid);

    if (isSourceNew && isTargetNew) {
      // NOP: No existing scan to flag as modified. We'll add both scans later.
    } else if (isSourceNew && captureTreeMaps.scanIdByUuid[edge.targetUuid]) {
      // Scan "sourceUuid" will be uploaded; Scan "targetUuid" already exists in Capture Tree.
      existingScanUuidsForEdges.add(edge.targetUuid);
    } else if (isTargetNew && captureTreeMaps.scanIdByUuid[edge.sourceUuid]) {
      // Scan "targetUuid" will be uploaded; Scan "sourceUuid" already exists in Capture Tree.
      existingScanUuidsForEdges.add(edge.sourceUuid);
    }
  }
  const existingScanIdsForEdges: GUID[] = [...existingScanUuidsForEdges].map((uuid) => captureTreeMaps.scanIdByUuid[uuid]);
  for (const id of existingScanIdsForEdges) {
    captureTreeEntityIdsToModify.add(id);
  }

  // Add all ancestor clusters of "existingScanUuidsForEdges".
  for (const scanUuid of existingScanUuidsForEdges) {
    const scanId = captureTreeMaps.scanIdByUuid[scanUuid];
    const scan = captureTreeMaps.entityById[scanId];
    assert(scan.parentId);

    let ancestor: CaptureTreeEntity | undefined = captureTreeMaps.entityById[scan.parentId];
    while (ancestor && isClusterEntity(ancestor)) {
      if (!captureTreeEntityIdsToModify.has(ancestor.id)) {
        captureTreeEntityIdsToModify.add(ancestor.id);
        captureTreeClusters.push({
          id: ancestor.id,
        });
      }
      ancestor = ancestor.parentId ? captureTreeMaps.entityById[ancestor.parentId] : undefined;
    }
  }

  const newClusterIds = new Set<GUID>();

  // Add all ancestor clusters of "newScanUuids", if existing, and create missing clusters as needed.
  for (const scanUuid of newScanUuids) {
    if (!lsDataV2.scansByUuid[scanUuid]) {
      // Someone added an extra *.gls file to the folder. It's "out of spec", but convenient to allow it.
      // Since we don't know the location in the tree, addScansToRevisionAndMergeToMainHelper() will add
      // this scan directly under the RootEntity.
      continue;
    }
    const { ancestorUuids } = lsDataV2.scansByUuid[scanUuid];
    // Iterate [cluster-below-root, ..., grantparent-cluster, parent-cluster]
    for (let i = 1; i < ancestorUuids.length - 1; i++) {
      const parentUuid = ancestorUuids[i - 1];
      const clusterUuid = ancestorUuids[i];
      const captureTreeCluster = captureTreeRootAndClustersByUuid[clusterUuid] as (CreateClusterEntityWithId | undefined);
      if (captureTreeCluster) {
        // Flag existing cluster as modified:
        if (!captureTreeEntityIdsToModify.has(captureTreeCluster.id) && !newClusterIds.has(captureTreeCluster.id)) {
          captureTreeEntityIdsToModify.add(captureTreeCluster.id);
          captureTreeClusters.push({
            id: captureTreeCluster.id,
          });
        }
      } else {
        const lsCluster = lsDataV2.clustersByUuid[clusterUuid];
        const parentId = captureTreeRootAndClustersByUuid[parentUuid]?.id;
        assert(parentId, `Parent not found for cluster with UUID ${clusterUuid} and parent UUID ${parentUuid}`);

        const cluster: CreateClusterEntityWithId = {
          id: generateGUID(),
          name: lsCluster.name,
          parentId,
          pose: getIdentityPose(),
        };
        newClusterIds.add(cluster.id);
        captureTreeClusters.push(cluster);
        captureTreeRootAndClustersByUuid[lsCluster.uuid] = cluster;
      }
    }
  }

  return {
    captureTreeEntityIdsToModify,
    captureTreeClusters,
    existingScanIdsForEdges,
    captureTreeRootAndClustersByUuid,
    captureTreeMaps,
  };
}

/**
 * Creates a capture tree revision that is ready to contain ELS scans uploaded by the user.
 * If the main revision did not have a root and a "ELS" cluster yet it also creates those entities.
 *
 * @param projectApiClient
 * @param lsDataV2 Metadata from the scanner.
 * @param glsFilesToUpload GLS files to upload, excluding scans that already exist in the Capture Tree.
 * @throws {Error} if it fails to create the revision, root or cluster
 * @returns Existing and new entities, for addScansToRevisionAndMergeToMainHelper().
 */
export async function createRevisionForElsScans(
  projectApiClient: ProjectApi, lsDataV2: ReadLsDataV2Response, glsFilesToUpload: File[]
): Promise<CreateRevision> {
  // Get capture tree entities for main revision
  let captureTreeEntities = await projectApiClient.getCaptureTreeForMainRevision();

  // If main revision root and/or ELS cluster are defined we need their IDs to create the revision
  let mainRevisionRoot = getRootEntity(captureTreeEntities);
  if (!mainRevisionRoot) {
    await createRevisionForRootEntity(projectApiClient);
    // It's easiest to just fetch the capture tree again, to get all attributes of the RootEntity.
    captureTreeEntities = await projectApiClient.getCaptureTreeForMainRevision();
    mainRevisionRoot = captureTreeEntities.filter(isRootEntity)[0];
    assert(mainRevisionRoot, "RootEntity was created, but could not be found afterwards");
  }

  const {
    captureTreeEntityIdsToModify,
    captureTreeClusters,
    captureTreeRootAndClustersByUuid,
    existingScanIdsForEdges,
    captureTreeMaps,
  } = calculateCaptureTreeChanges(captureTreeEntities, mainRevisionRoot, lsDataV2, glsFilesToUpload);

  // Create revision.
  const registrationRevision = await projectApiClient.createRegistrationRevision(
    [...captureTreeEntityIdsToModify], /* registrationEdgeIds */ [], CaptureApiClient.dashboard
  );
  const registrationRevisionId = registrationRevision.id;

  // We need to flag the RootEntity as modified, otherwise ProjectAPI would try to delete it (which would fail).
  await projectApiClient.createOrUpdateRootEntityForRegistrationRevision({
    registrationRevisionId,
    requestBody: {
      id: mainRevisionRoot.id,
    },
  });

  if (captureTreeClusters.length > 0) {
    await projectApiClient.createOrUpdateClusterEntitiesForRegistrationRevision({
      registrationRevisionId,
      requestBody: captureTreeClusters,
    });
  }

  if (existingScanIdsForEdges.length > 0) {
    await projectApiClient.createOrUpdateScanEntitiesForRegistrationRevision({
      registrationRevisionId,
      requestBody: existingScanIdsForEdges.map((id) => ({ id })),
    });
  }

  return {
    registrationRevisionId,
    captureTreeRootAndClustersByUuid,
    captureTreeMaps,
  };
}
