import { useMemo } from "react";

import { ApiClient } from "@stellar/api-logic";

import { runtimeConfig } from "@src/runtime-config";
import { CLIENT_ID } from "@api/client-id";

let client: ApiClient;

/**
 * Initializes client to Core.API if not already initialized and returns it
 */
export function useCoreApiClient(): ApiClient {
  // Make sure the client is only created once
  const memoizedClient = useMemo(() => {
    client = new ApiClient({
      url: runtimeConfig.urls.apiBaseUrl,
      powerBiUrl: runtimeConfig.urls.powerBIBaseUrl,
      clientOptions: {
        headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "X-Holobuilder-Component": CLIENT_ID,
        },
      },
    });

    return client;
  }, []);

  return memoizedClient;
}
