import { Box, Breakpoint, Divider, Skeleton } from "@mui/material";
import { cardProperties } from "@utils/ui-utils";
import { CSSProperties } from "react";

/** Width of the cards depending on the breakpoint */
const integrationCardWidth: {
  [key in Breakpoint]: CSSProperties["width"];
} = {
  xs: "100%",
  sm: "48%",
  md: "48%",
  lg: "32%",
  xl: "32%",
};

/** Skeleton loading for the integration tile */
export function IntegrationSkeletonTile(): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        width: integrationCardWidth,
        height: "200px",
        marginTop: cardProperties.marginTop,
        boxShadow: cardProperties.boxShadow,
        borderRadius: cardProperties.borderRadius,
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Skeleton variant="rectangular" sx={{ width: "30%", height: "20px" }} />
      <Skeleton variant="text" />
      <Skeleton variant="text" width="70%" />
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton variant="text" sx={{ width: "25%" }} />
      </Box>
    </Box>
  );
}
