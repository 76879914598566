import { SphereDivider } from "@components/common/sphere-divider";
import { ExternalLinks } from "@components/external-links";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { Box, ListItemText, Stack, SvgIcon } from "@mui/material";
import { CoreAPITypes } from "@stellar/api-logic";
import { formatUserRoleType, userRoleDescription } from "@utils/data-display";
import { FaroSelect } from "@components/common/faro-select/faro-select";
import { LabelWithHelp } from "@components/common/label-with-help";
import { SelectRoleSubjectProps } from "@components/role/select-role-utils";
import GenericCheck from "@assets/icons/generic-check_l.svg?react";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { addTransparency } from "@utils/ui-utils";

interface Props<T> extends SelectRoleSubjectProps<T> {
  /** All the roles that are selectable */
  roles: T[];

  /** The label to show above the select dropdown */
  label: string;

  /** Whether to show the permission link at the end of selectors or not */
  shouldShowPermissionsLink: boolean;
}

/** Shows a selector in which user can choose a role for different subjects, i.e company, project, group */
export function SelectRole<
  T extends CoreAPITypes.EUserCompanyRole | CoreAPITypes.EUserProjectRole
>({
  roles,
  disabledRoles = [],
  isTableCell,
  selectedRole,
  onChange,
  onResetSelection,
  label,
  shouldShowPermissionsLink,
  placeholder,
  isRequired = true,
}: Props<T>): JSX.Element {
  return (
    <>
      {!isTableCell && <LabelWithHelp title={label} isRequired={isRequired} />}
      <FaroSelect
        labelId="select-role-label"
        id="select-role"
        size="small"
        variant={isTableCell ? "tableCell" : "outlined"}
        value={selectedRole ?? ""}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Type of onChange is too complex to represent.
        onChange={onChange}
        renderValue={
          selectedRole ? () => formatUserRoleType(selectedRole) : undefined
        }
        placeholder={placeholder}
        onResetSelection={onResetSelection}
      >
        {roles.map((role) => (
          <FaroMenuItem
            key={role}
            value={role}
            disabled={disabledRoles.includes(role)}
            sx={{
              "&.Mui-selected": {
                backgroundColor: addTransparency({
                  color: sphereColors.gray500,
                  alpha: EDecimalToHex.thirtyEight,
                }),
              },
              m: "8px",
              textWrap: "wrap",
              width: isTableCell ? "250px" : "undefined",
            }}
          >
            <ListItemText
              key={role}
              sx={{
                "& .MuiListItemText-primary": {
                  fontSize: "12px",
                  fontWeight: 600,
                },
                "& .MuiListItemText-secondary": {
                  fontSize: "11px",
                  fontWeight: 400,
                },
              }}
              primary={
                <Stack
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    color:
                      role === selectedRole ? sphereColors.blue500 : "inherit",
                  }}
                >
                  {formatUserRoleType(role)}
                  {role === selectedRole && (
                    <SvgIcon
                      inheritViewBox
                      component={GenericCheck}
                      sx={{
                        height: "16px",
                        width: "16px",
                      }}
                    />
                  )}
                </Stack>
              }
              secondary={userRoleDescription(role)}
            />
          </FaroMenuItem>
        ))}

        {shouldShowPermissionsLink && (
          <Box sx={{ mb: "8px" }}>
            <SphereDivider sx={{ m: "8px" }} />
            <ExternalLinks
              link={"permissionsLevelsHelp"}
              text="Learn about permissions levels"
              sx={{
                fontSize: "12px",
                px: "16px",
                mx: "8px",
                color: sphereColors.blue500,
              }}
            />
          </Box>
        )}
      </FaroSelect>
    </>
  );
}
