import { neutral, FontWeights, orange } from "@faro-lotv/flat-ui";
import { Chip, Typography } from "@mui/material";
import { CSSProperties } from "react";

interface Props {
  /** The distance from bottom  */
  bottom?: CSSProperties["bottom"];
}

/**
 * @returns a red chips to indicate that the feature is in beta
 */
export function FaroBetaBadge({ bottom }: Props): JSX.Element {
  return (
    <Chip
      label={
        <Typography
          sx={{
            color: neutral[0],
            /** Using pixel instead of rem units because the Badge is using it is set in pixel */
            fontSize: "10px",
            fontWeight: FontWeights.Bold,
            /** Using pixel instead of rem units because the Badge is using it is set in pixel */
            lineHeight: "12px",
          }}
        >
          BETA
        </Typography>
      }
      sx={{
        py: "2px",
        px: "4px",
        height: "unset",
        backgroundColor: orange[500],
        width: "fit-content",

        "& .MuiChip-label": {
          p: 0,
        },

        position: "relative",
        bottom,
      }}
    />
  );
}
