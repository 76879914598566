/**
 * Determines the hexadecimal decimal value for a given number in decimal.
 * Useful in transparencies and other color props where we convert to HEX
 * values ranging from 0 to 255.
 * The key is the number in decimal and the value the conversion to hexadecimal.
 */
export enum EDecimalToHex {
  zero = "00",
  sixteen = "10",
  twenty = "14",
  twentySix = "1A",
  thirtyTwo = "20",
  thirtyEight = "26",
  forty = "28",
  fiftyOne = "33",
  sixtyFour = "40",
  seventy = "46",
  seventySeven = "4D",
  hundredTwo = "66",
  hundredTwentyEight = "80",
  hundredFortyFour = "90",
  hundredFiftyThree = "99",
  hundredSixtySix = "A6",
  twoHundredThree = "CB",
  twoHundredFour = "CC",
}

// To get more colors, check faro-theme.ts file in LotvMonorepo: libs/flat-ui/src/components/faro-theme.ts
/** All the colors that is being used in the app */
export const sphereColors = {
  pureWhite: "#FFFFFF",

  gray50: "#F8F9FA",
  gray100: "#F0F2F5",
  gray200: "#E0E4EB",
  gray300: "#C2C9D6",
  gray400: "#A3AEC2",
  gray500: "#8592AD",
  gray600: "#667799",
  gray700: "#525F7A",
  gray750: "#3D485C",
  gray800: "#363C4D",
  gray950: "#20252E",

  black: "#000000",

  red50: "#FFEBEB",
  red100: "#FFEBEB",
  red300: "#FC8787",
  red500: "#FA2424",
  red600: "#E50505",
  red700: "#B30404",
  red800: "#820D03",

  green50: "#E8FCF3",
  green100: "#C7F9DE",
  green600: "#12B058",
  green700: "#0D8040",

  blue50: "#EFF4FE",
  blue100: "#DEE8FD",
  blue200: "#AEC7F9",
  blue300: "#7EA7F6",
  blue400: "#4E86F3",
  blue500: "#1F65F0",
  blue600: "#0E4ECC",
  blue700: "#0B3C9D",
  blue800: "#082A6E",

  yellow100: "#FFF8E5",
  yellow200: "#FFE9B2",
  yellow600: "#E8A600",
  yellow650: "#CC9200",

  orange500: "#F06E1D",
  orange600: "#D6621A",

  purple50: "#F4EBFF",
  purple100: "#EBD9FF",
  purple500: "#8B26FF",
  purePurple: "#FF00FF",

  userModuleBackground: "#212121",
};

/** Defines some constants in the colors named by how are they used. */
export const colorConst = {
  /** Used for placeholder  */
  placeholder: sphereColors.gray500,

  normalFont: sphereColors.gray800,

  /** Background color for the tag and badge to indicate that something is new */
  newFeatureIndicator: sphereColors.orange500,

  /** Font/background color for dev features which aren't visible to regular users */
  devFeature: sphereColors.purePurple,
};
