import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { MemberTypes } from "@custom-types/member-types";
import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityId,
} from "@reduxjs/toolkit";
import { APITypes } from "@stellar/api-logic";
import {
  MembersState,
  UpdateMemberRoleResult,
} from "@src/store/members/members-slice-helper";
import { inviteMembersToSnapshot } from "@store/snapshots/snapshots-slice";
import { getMemberId } from "@utils/member-utils";
import {
  fetchCompanyMemberDetails,
  fetchCompanyMembers,
  removeMemberFromCompany,
  setMemberPermissionsInCompany,
  updateMemberRoleInWorkspace,
} from "@store/members/members-slice-thunk";

/**
 * Creates an entity adapter to store a map with all the members that belong to the company.
 * Since we can also fetch teams as member type, they will also be stored here.
 */
export const membersAdapter: EntityAdapter<MemberTypes, EntityId> =
  createEntityAdapter({
    selectId: (member) => getMemberId(member),
  });

const initialState: MembersState = {
  ...membersAdapter.getInitialState(),
  fetched: {
    hasFetchedWorkspaceMembers: false,
  },
  fetching: {
    isFetchingCompanyMembers: false,
    isFetchingCompanyMemberDetails: false,
    isRemovingCompanyMember: false,
  },
};

/**
 * Update a member role in workspace store.
 */
function updateMemberRoleInMemberStore(
  state: MembersState,
  result: UpdateMemberRoleResult
): void {
  const member = membersAdapter
    .getSelectors()
    .selectById(state, result.identity);

  // If the member is in the store update the member role
  if (member) {
    membersAdapter.updateOne(state, {
      id: getMemberId(member),
      changes: {
        role: result.role,
      },
    });
  }
}

/**
 * Update a member in workspace store.
 */
function updateMemberInMemberStore(
  state: MembersState,
  memberId: APITypes.UserId,
  changes: Partial<MemberTypes>
): void {
  const member = membersAdapter.getSelectors().selectById(state, memberId);

  // If the member is in the store update the member role
  if (member) {
    membersAdapter.updateOne(state, {
      id: getMemberId(member),
      changes,
    });
  }
}

/**
 * Slice to access state of loaded company members
 */
const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    /**
     * Accepts a single member entity and adds or replaces it.
     *
     * @param state store state
     * @param entity Project to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setOne: membersAdapter.setOne,
    /**
     * Accepts an array of member entities, and adds or replaces them.
     *
     * @param state store state
     * @param entity Projects to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setMany: membersAdapter.setMany,
    /**
     * Removes all member entities from the store.
     *
     * @param state store state
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeAll: membersAdapter.removeAll,
    /**
     * Accepts an array of member IDs, and removes each member entity with those IDs if they exist.
     *
     * @param state store state
     * @param entity Project Ids to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeMany: membersAdapter.removeMany,
    /**
     * Accepts a single member IDs, and removes the member entity with that ID if it exists.
     *
     * @param state store state
     * @param entity Project Id to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeOne: membersAdapter.removeOne,
    resetMembersState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompanyMembers.pending, (state, action) => {
        state.fetching.isFetchingCompanyMembers = true;
      })
      .addCase(fetchCompanyMembers.fulfilled, (state, action) => {
        membersAdapter.upsertMany(state, action.payload);

        state.fetching.isFetchingCompanyMembers = false;
        state.fetched.hasFetchedWorkspaceMembers = true;
      })
      .addCase(fetchCompanyMembers.rejected, (state, action) => {
        state.fetching.isFetchingCompanyMembers = false;
      })

      .addCase(fetchCompanyMemberDetails.pending, (state, action) => {
        state.fetching.isFetchingCompanyMemberDetails = true;
      })
      .addCase(fetchCompanyMemberDetails.fulfilled, (state, action) => {
        membersAdapter.upsertOne(state, action.payload);
        state.fetching.isFetchingCompanyMemberDetails = false;
      })
      .addCase(fetchCompanyMemberDetails.rejected, (state, action) => {
        state.fetching.isFetchingCompanyMemberDetails = false;
      })

      .addCase(removeMemberFromCompany.pending, (state, action) => {
        state.fetching.isRemovingCompanyMember = true;
      })
      .addCase(removeMemberFromCompany.fulfilled, (state, action) => {
        state.fetching.isRemovingCompanyMember = false;
        membersAdapter.removeOne(state, getMemberId(action.payload));
      })
      .addCase(removeMemberFromCompany.rejected, (state, action) => {
        state.fetching.isRemovingCompanyMember = false;
      })

      .addCase(updateMemberRoleInWorkspace.pending, (state, action) => {
        state.fetching.isFetchingCompanyMembers = true;
      })
      .addCase(updateMemberRoleInWorkspace.fulfilled, (state, action) => {
        updateMemberRoleInMemberStore(state, action.payload);
        state.fetching.isFetchingCompanyMembers = false;
      })
      .addCase(updateMemberRoleInWorkspace.rejected, (state, action) => {
        state.fetching.isFetchingCompanyMembers = false;
      })

      .addCase(setMemberPermissionsInCompany.fulfilled, (state, action) => {
        updateMemberInMemberStore(
          state,
          action.payload.identity,
          action.payload
        );
      })
      .addCase(setMemberPermissionsInCompany.rejected, (state, action) => {
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      // Listen to the inviteMembersToSnapshot action to reset the hasFetchedWorkspaceMembers flag
      // This is to make sure that the members are fetched again after inviting members to a snapshot
      // because the members list might have changed
      .addCase(inviteMembersToSnapshot.fulfilled, (state, action) => {
        state.fetched.hasFetchedWorkspaceMembers = false;
      });
  },
});

export const {
  setOne,
  setMany,
  removeAll,
  removeMany,
  removeOne,
  resetMembersState,
} = membersSlice.actions;

export const membersReducer = membersSlice.reducer;
