import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import styles from "@components/table/projects/projects-table.module.css";
import { SdbProject } from "@custom-types/project-types";
import { isTeam } from "@utils/team-utils";
import { Link } from "react-router-dom";
import {
  generateMemberProfileRoute,
  generateTeamDetailsRoute,
} from "@router/router-helper";
import { getProjectManagerName } from "@utils/project-utils";

interface Props extends BaseCompanyIdProps {
  /** All the information from one project */
  project: SdbProject;

  /** Whether the user has permission to access the group's page and view all company groups */
  canViewAllCompanyGroups: boolean;
}

/**
 * Component responsible for displaying the project manager's name as a clickable link or plain text.
 * The link redirects the user to either the member profile or team details, depending on the context.
 */
export function ProjectManagerDisplay({
  project,
  companyId,
  canViewAllCompanyGroups,
}: Props): JSX.Element {
  const projectManager = project.managers.projectManager;
  const projectManagerIdentity = projectManager?.identity ?? "";

  if (projectManager) {
    if (canViewAllCompanyGroups) {
      return (
        <Link
          to={
            isTeam(projectManager)
              ? generateTeamDetailsRoute(companyId, projectManager.identity)
              : generateMemberProfileRoute(companyId, projectManager.identity)
          }
          className={styles["removed-underline"]}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <var>{getProjectManagerName(project)}</var>
        </Link>
      );
    } else {
      return <var>{getProjectManagerName(project)}</var>;
    }
  } else {
    return <var>{projectManagerIdentity}</var>;
  }
}
