import { Box, Typography } from "@mui/material";
import { DropHandler } from "@components/common/sphere-dropzone/drop-handler";
import { ReactSetStateFunction } from "@custom-types/types";
import { useFileUpload } from "@hooks/use-file-upload";
import { ChangeEvent, DragEvent, useCallback, useState } from "react";
import { DropzoneElements } from "@components/common/sphere-dropzone/dropzone-elements";
import { sphereColors } from "@styles/common-colors";
import { useToast } from "@hooks/use-toast";
import { FILE_SIZE_MULTIPLIER } from "@utils/file-utils";
import { isNumber } from "lodash";
import {
  FileUploadTaskContext,
  UploadedFileResponse,
  UploadFileParams,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { isValidFile } from "@hooks/file-upload-utils";
import { haveFileNamesValidLength, MAX_FILE_NAME_LENGTH } from "@hooks/upload-tasks/upload-tasks-utils";
import { collectFilesRecursivelyFromItems } from "@components/common/sphere-dropzone/collect-files";
import { assert } from "@faro-lotv/foundation";

interface Props {
  /** Avatar component of user. If provided existingImageUrl will be ignored */
  avatar?: JSX.Element;

  /** Existing url of an image to display inside the dropzone */
  existingImageUrl?: string;

  /** True if uploading is in progress */
  isLoading: boolean;

  /** Callback function to trigger to set the loading state */
  setIsLoading: ReactSetStateFunction<boolean>;

  /** Callback function on file upload complete */
  onUploadComplete(
    uploadedResponse: UploadedFileResponse,
    context: FileUploadTaskContext
  ): void;

  /** Callback function which triggers on delete button click */
  onDeleteButtonClick?(): void;

  /** Text to show in delete icon tooltip */
  deleteIconTooltipText?: string;

  /** Give a title for the drag and drop zone */
  titleDragAndDrop?: string;

  /** Give the button upload file */
  hasUploadFileButton?: boolean;

  /** Whether the supported format should show in the dropzone */
  shouldShowSupportedFormats?: boolean;

  /** Whether the size limit should show in the dropzone */
  shouldShowSizeLimit?: boolean;

  /** Whether the dropzone should handle multiple file or not */
  shouldAllowMultiUpload?: boolean;

  /** Whether the dropzone should handle folders or not */
  shouldAllowFolderUpload?: boolean;

  /** Whether the dropzone should handle files */
  shouldAllowFiles?: boolean;

  /** List of file extensions that are allowed in the dropzone, e.g. ["gls"] */
  allowedExtensions?: string[];

  /** First part of instruction, can be different based on the usage of the dropzone */
  instruction?: string;

  /** Maximum file size the dropzone should allow to upload */
  maxFileSize?: number;

  /**
   * Additional information of the file upload task; required for most cases.
   *
   * Staging Area (ImportData dialog) uses the SphereDropzone in a way such that the context is never referenced:
   * Neither initiateFileUpload() nor initiateMultipleFileUpload() from SphereDropzone are called in this case,
   * since ImportData's onSelectFilesInternal() doesn't call the confirmCallback().
   * Therefore the context was made optional, and is instead asserted before each usage.
   */
  context?: FileUploadTaskContext;

  /**
   * Callback function when new files are selected.
   * `confirmCallback` starts the upload, but the parent component can skip the call and use its own implementation.
   */
  onSelectFiles?(files: FileList | File[], confirmCallback: () => void): void;
}

/** Contains all the sorted default file extensions that the default dropzone support  */
const DEFAULT_FILE_EXTENSIONS_WHITE_LIST = ["gif", "jpeg", "jpg", "png", "svg"];

/** Default maximum MB size of the file to upload */
const DEFAULT_MAX_UPLOAD_SIZE_MB: number = 10;

/** Maximum MB size of the file to use normal upload, higher than that will use chunk upload */
const MAX_FILE_SIZE_FOR_NORMAL_UPLOAD: number = 5;

/**
 * Renders the dropzone area to upload a file
 */
export function SphereDropzone({
  avatar,
  existingImageUrl,
  isLoading,
  setIsLoading,
  onUploadComplete,
  onDeleteButtonClick,
  deleteIconTooltipText,
  titleDragAndDrop,
  hasUploadFileButton,
  shouldShowSupportedFormats,
  shouldShowSizeLimit,
  allowedExtensions = DEFAULT_FILE_EXTENSIONS_WHITE_LIST,
  instruction,
  maxFileSize = DEFAULT_MAX_UPLOAD_SIZE_MB,
  shouldAllowMultiUpload = false,
  shouldAllowFolderUpload = false,
  shouldAllowFiles = true,
  context,
  onSelectFiles,
}: Props): JSX.Element {
  const {
    uploadSingleFile,
    uploadFileWithChunks,
    uploadMultipleFiles,
    validateAndAddFailedTask,
  } = useFileUpload();
  const { showToast } = useToast();

  const [fileInputEl, setFileInputEl] = useState<HTMLInputElement | null>(null);
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const openFileExplorer = useCallback(() => {
    if (!fileInputEl) {
      return;
    }
    // Allow to select folders only when openFileExplorer
    if (shouldAllowFiles === false) {
      // needed to to this cause react does not allow to set these attributes in the DOM/HTML
      // ... is not assignable to type 'DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
      fileInputEl.setAttribute("webkitdirectory", "true");
      fileInputEl.setAttribute("directory", "true");
    }
    // https://stackoverflow.com/a/76926836/4555850
    // We need oncancel to make this work: canBeDropped={!isFileExplorerOpen}.
    fileInputEl.oncancel = () => setIsFileExplorerOpen(false);
    fileInputEl.click();
    setIsFileExplorerOpen(true);
  }, [fileInputEl, shouldAllowFiles]);

  /** Selects files from file explorer after click on the dropzone */
  function selectFileFromDialog(event: ChangeEvent<HTMLInputElement>): void {
    setIsFileExplorerOpen(false);
    // When a folder was selected, we get the individual files instead of a FileSystemDirectoryHandle/Entry.
    // But we could still recover the folder structure using files[*].webkitRelativePath.
    const files = event.target.files;
    if (files) {
      const areValidFilesNames = haveFileNamesValidLength(files);
      if (!areValidFilesNames) {
        showToast({
          message: `Folder or file name is too large, please change it to be shorter than ${MAX_FILE_NAME_LENGTH + 1} characters`,
          type: "warning",
        });
        return;
      }
    }
    handleFileSelection(files);
  }

  /** Selects files from drag and drop */
  async function selectFileFromDrop(
    event: DragEvent<HTMLElement>
  ): Promise<void> {
    event.preventDefault();
    if (event.dataTransfer) {
      /* Validate files/folders names, if too long it can lead to error or unexpected behaviour */
      const areValidFilesNames = haveFileNamesValidLength(event.dataTransfer.files);
      if (!areValidFilesNames) {
        showToast({
          message: `Folder or file name is too large, please change it to be shorter than ${MAX_FILE_NAME_LENGTH + 1} characters`,
          type: "warning",
        });
        return;
      }
      if (event.dataTransfer.items?.length >= 0) {
        const files = await collectFilesRecursivelyFromItems(
          event.dataTransfer.items,
          shouldAllowFolderUpload,
          shouldAllowFiles,
          allowedExtensions
        );
        handleFileSelection(files);
      } else {
        handleFileSelection(event.dataTransfer.files);
      }
    }
  }

  /** Handle files after selection or drop */
  function handleFileSelection(files: FileList | File[] | null): void {
    // Early return if there is no file dropped
    if (!files || files.length === 0) {
      return;
    }
    const allowedFiles: File[] = [];
    for (const file of files) {
      const validatedFile = isValidFile({
        file,
        allowedExtensions,
        maxFileSize,
      });
      if (validatedFile.isValid) {
        allowedFiles.push(file);
      }
    }

    // Waiting for confirmation to start uploading if onSelectFiles callback is provided.
    if (onSelectFiles) {
      onSelectFiles(allowedFiles, () => {
        if (shouldAllowMultiUpload) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
          initiateMultipleFileUpload(allowedFiles);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
          initiateFileUpload(allowedFiles[0]);
        }
      });

      return;
    }

    // Proceed uploading if there is no confirmation required
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    shouldAllowMultiUpload
      ? initiateMultipleFileUpload(files)
      : initiateFileUpload(files[0]);
  }

  /** Triggers when file upload starts */
  function onUploadStart(): void {
    // Progress loader should not visible to make the dropzone available for further upload
    if (!shouldAllowMultiUpload) {
      setIsLoading(true);
    }
  }

  /** Triggers to set the file upload progress value */
  function onUploadProgress(progress: ProgressEvent | number): void {
    const uploadProgress = isNumber(progress)
      ? progress
      : (progress.loaded / progress.total) * 100;

    setUploadProgress(uploadProgress);
  }

  /** Triggers when file is selected or dropped */
  async function initiateFileUpload(file: File | undefined): Promise<void> {
    // Early return if file is not available
    if (!file) {
      return;
    }

    const validatedFile = isValidFile({ file, allowedExtensions, maxFileSize });
    if (!validatedFile.isValid) {
      showToast({
        message: validatedFile.message,
        description: validatedFile.description,
        type: "error",
      });

      return;
    }

    // Need to reset the progress to start from 0 next time
    setUploadProgress(0);

    assert(context, "SphereDropzone: Upload context is undefined, but required in this case");
    const uploadParams: UploadFileParams = {
      file,
      onUploadStart,
      onUploadProgress,
      onUploadComplete,
      context,
    };

    const shouldUseChunkUpload =
      file.size >
      MAX_FILE_SIZE_FOR_NORMAL_UPLOAD *
        FILE_SIZE_MULTIPLIER *
        FILE_SIZE_MULTIPLIER;

    // Use chunk upload if file size is bigger than MAX_FILE_SIZE_FOR_NORMAL_UPLOAD
    const isUploaded = shouldUseChunkUpload
      ? await uploadFileWithChunks(uploadParams)
      : await uploadSingleFile(uploadParams);

    // Hiding the loader spinner if upload is failed
    if (!isUploaded) {
      setIsLoading(false);
    }
  }

  /** This function is not used in Staging Area, but instad the function with same name in "import-data.tsx". */
  async function initiateMultipleFileUpload(
    files: FileList | File[]
  ): Promise<void> {
    // Early return if projectId is not provided
    // Only for type checking
    assert(context, "SphereDropzone: Upload context is undefined, but required in this case");
    if (!context.projectId) {
      return;
    }

    const uploadableFiles = [...files].filter((file) =>
      validateAndAddFailedTask({
        file,
        allowedExtensions,
        maxFileSize,
        context,
      })
    );

    // Return if there is no uploadable file
    if (!uploadableFiles.length) {
      return;
    }

    const uploadParams: UploadMultipleFilesParams = {
      files: uploadableFiles,
      onUploadStart,
      onUploadProgress,
      onUploadComplete,
      context,
    };

    await uploadMultipleFiles(uploadParams);
  }

  return (
    <Box component="div">
      {(titleDragAndDrop || hasUploadFileButton) && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {titleDragAndDrop && (
            <Typography
              sx={{
                fontWeight: 600,
                color: sphereColors.gray800,
                mb: "10px",
                fontSize: "14px",
              }}
            >
              {titleDragAndDrop}
            </Typography>
          )}

          {hasUploadFileButton && (
            <Typography
              sx={{
                fontWeight: 600,
                color: sphereColors.blue500,
                mb: "10px",
                fontSize: "12px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={openFileExplorer}
            >
              Upload file
            </Typography>
          )}
        </Box>
      )}
      <DropHandler
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onDrop={selectFileFromDrop}
        canBeDropped={!isFileExplorerOpen}
        onClick={openFileExplorer}
        isLoading={isLoading}
        shouldAllowMultiUpload={shouldAllowMultiUpload}
      >
        <DropzoneElements
          avatar={avatar}
          isLoading={isLoading}
          uploadProgress={uploadProgress}
          existingImageUrl={existingImageUrl}
          setFileInputEl={setFileInputEl}
          selectFileFromDialog={selectFileFromDialog}
          onDeleteButtonClick={onDeleteButtonClick}
          deleteIconTooltipText={deleteIconTooltipText}
          shouldShowSupportedFormats={shouldShowSupportedFormats}
          shouldShowSizeLimit={shouldShowSizeLimit}
          allowedExtensions={allowedExtensions}
          instruction={instruction}
          maxFileSize={maxFileSize}
          shouldAllowMultiUpload={shouldAllowMultiUpload}
          shouldAllowFiles={shouldAllowFiles}
        />
      </DropHandler>
    </Box>
  );
}
