import { StepIconProps, SvgIcon } from "@mui/material";
import ActiveSvg from "@assets/icons/new/thick-circle_24px.svg?react";
import CompletedSvg from "@assets/icons/new/checkmark-circle-fill_24px.svg?react";
import TodoSvg from "@assets/icons/new/gray-circle_24px.svg?react";
import ErrorSvg from "@assets/icons/new/exclamation-mark-circle-fill_24px.svg?react";
import { sphereColors } from "@styles/common-colors";
import { SphereTooltip } from "@components/common/sphere-tooltip";

/**
 * Reduce the tooltip delay since it isn't obvious that the icons have a tooltip and we want to offer faster
 * feedback to the user this way.
 */
const ENTER_DELAY = 200;

// eslint-disable-next-line @typescript-eslint/naming-convention -- names given by package
export function DataManagementStepIcon({ active, completed, error }: StepIconProps): JSX.Element {
  if (completed) {
    return (
      <SphereTooltip
        dataTestId="sa-step-icon-completed"
        title="The step has been finished successfully!"
        enterDelay={ENTER_DELAY}
        boxProps={{ display: "flex" }}
      >
        <SvgIcon inheritViewBox component={CompletedSvg} sx={{ color: sphereColors.blue500 }} />
      </SphereTooltip>
    );
  } else if (error) {
    return (
      <SphereTooltip
        dataTestId="sa-step-icon-error"
        title="An error has occurred!"
        enterDelay={ENTER_DELAY}
        boxProps={{ display: "flex" }}
      >
        <SvgIcon inheritViewBox component={ErrorSvg} sx={{ color: sphereColors.red500 }} />
      </SphereTooltip>
    );
  } else if (active) {
    return (
      <SphereTooltip
        dataTestId="sa-step-icon-active"
        title="The step is currently running. Please wait."
        enterDelay={ENTER_DELAY}
        boxProps={{ display: "flex" }}
      >
        <SvgIcon inheritViewBox component={ActiveSvg} sx={{ color: sphereColors.blue500 }} />
      </SphereTooltip>
    );
  } else {
    return (
      <SphereTooltip
        dataTestId="sa-step-icon-todo"
        title="This step requires previous steps to be completed first."
        enterDelay={ENTER_DELAY}
        boxProps={{ display: "flex" }}
      >
        <SvgIcon inheritViewBox component={TodoSvg} />
      </SphereTooltip>
    );
  }
}
