import { FaroButtonContained } from "@components/common/faro-button-contained";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { BaseMemberProps } from "@custom-types/member-types";
import { Box } from "@mui/material";
import { MemberTabs } from "@router/route-params";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { getMailToLink } from "@utils/email-utils";
import { getPrettyName, isMemberActive } from "@utils/user-utils";
import { CreateGroupsPermissionToggle } from "@components/user-info/create-groups-permission-toggle";
import { SphereUpdatableAvatar } from "@components/user-info/sphere-updatable-avatar";
import { CoreAPITypes } from "@stellar/api-logic";
import { border } from "@utils/ui-utils";
import { PartialNull } from "@custom-types/types";
import { useMembersUtils } from "@hooks/use-member-utils";

/** Width for the user info component shown on the right side of the page */
export const userInfoWidth = "340px";

/** Height for the user info component shown on the right side of the page */
export const userInfoHeight = "443px";

/**
 * Component that shows the information of the user in the profile overview page,
 * allows user to open profile and give create groups permission.
 */
export function UserInfo({
  member,
}: PartialNull<BaseMemberProps>): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const isCurrentUser = member?.identity === currentUser?.identity;

  const { openProfilePage } = useMembersUtils();

  /** Handles the click on the edit profile button */
  function onClick(): void {
    openProfilePage({
      source: "user info",
      userIdentity: currentUser?.id,
      memberTab: MemberTabs.account,
    });
  }

  if (!member) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Box
      component="div"
      sx={{
        border: `1px solid ${sphereColors.gray200}${EDecimalToHex.hundredTwentyEight}`,
        width: userInfoWidth,
        height: userInfoHeight,
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Container for user details like avatar, name and email. */}
      <Box
        component="div"
        sx={{
          marginTop: "78px",
        }}
      >
        {/* Container for avatar */}
        <Box
          component="div"
          sx={{
            marginBottom: "18px",
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
          }}
        >
          <SphereUpdatableAvatar member={member} />
        </Box>

        {/* Container for name and email */}
        <Box
          component="div"
          sx={{
            paddingX: "10px",
          }}
        >
          {/* Container for name */}
          {isMemberActive(member) && (
            <Box
              component="div"
              sx={{
                fontSize: isMemberActive(member) ? "20px" : "14px",
                fontWeight: "bold",
                color: sphereColors.blue500,
                textAlign: "center",
              }}
            >
              <SphereTooltip
                title={<var>{getPrettyName(member)}</var>}
                shouldSkipWrapper
              >
                <Box component="div" sx={withEllipsis}>
                  {<var>{getPrettyName(member)}</var>}
                </Box>
              </SphereTooltip>
            </Box>
          )}

          {/* Container for email. Should not be shown for user-groups */}
          {member.kind !== "user-group" && (
            <Box
              component="div"
              sx={{
                fontSize: isMemberActive(member) ? "10px" : "14px",
                color: isMemberActive(member)
                  ? sphereColors.gray500
                  : sphereColors.blue500,
                fontWeight: isMemberActive(member) ? "normal" : "bold",
                textAlign: "center",
              }}
            >
              <SphereTooltip
                title={
                  <var>
                    {getMailToLink(member.email, {
                      color: sphereColors.pureWhite,
                    })}
                  </var>
                }
                shouldSkipWrapper
              >
                <Box component="div" sx={withEllipsis}>
                  <var>{member.email}</var>
                </Box>
              </SphereTooltip>
            </Box>
          )}

          {/* Edit profile button */}
          {isCurrentUser && (
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "28px",
              }}
            >
              <FaroButtonContained onClick={onClick}>
                Edit profile
              </FaroButtonContained>
            </Box>
          )}
        </Box>
      </Box>

      {
        // Only show this information in group managers since they are the only ones
        // that can get the permission to create groups.
        // Enterprise admins always have this permission and enterprise viewers can't have it.
        member.role === CoreAPITypes.EUserCompanyRole.companyManager && (
          <Box
            component="div"
            sx={{
              marginX: "24px",
              borderTop: border(sphereColors.gray200),
              paddingY: "24px",
            }}
          >
            <CreateGroupsPermissionToggle member={member} />
          </Box>
        )
      }
    </Box>
  );
}
