import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { BaseCoreApiClientProps } from "@store/store-types";

interface CoreApiWithCompanyIdPropsWithMemberId extends BaseCoreApiClientProps {
  /** The memberId of the member to fetch groups for */
  memberId: string;
}

export const fetchCompanyMemberGroups = createAsyncThunk<
  SphereDashboardAPITypes.CompanyMemberGroup[],
  CoreApiWithCompanyIdPropsWithMemberId,
  { state: RootState }
>(
  "companyMemberGroups/fetchCompanyMemberGroups",
  async ({ coreApiClient, memberId }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error("No companyId was given to fetch company member groups");
    }

    try {
      const data = await coreApiClient.V3.SDB.getCompanyMemberGroups(
        selectedSdbCompanyId,
        memberId
      );

      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (_, api) => {
      // Skip fetching if groups have already been fetched
      return !api.getState().companyMemberGroups.groups;
    },
  }
);
