import { FileUploadTask } from "@custom-types/file-upload-types";
import { CloseIcon } from "@faro-lotv/flat-ui";
import { Box, Divider, Stack } from "@mui/material";
import { ProgressCard } from "@components/progress-overview/progress-card";
import { useAppSelector } from "@store/store-helper";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { colorConst } from "@styles/common-colors";

interface Props {
  /** Title of the section eg. Failed, In Progress */
  label: "Failed" | "In progress";

  /** List of task */
  tasks: FileUploadTask[];
}

/** Renders a failed or in-progress section in project overview */
export function ProgressOverviewSectionItem({
  label,
  tasks,
}: Props): JSX.Element {
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const speedMbit = isDevModeEnabled ? tasks.reduce((acc, task) => acc + (task.speedMbit ?? 0), 0).toFixed(2) : 0;
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          justifyContent: "space-between",
          textTransform: "uppercase",
          color: "gray850",
          fontWeight: "bold",
          fontSize: "0.8em",
          mx: 4,
          mt: 2,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CloseIcon sx={{ color: "red500" }} /> {label} &nbsp;
          <var style={{ opacity: "0.6" }}>({tasks.length})</var>
        </div>
        <var style={{ color: isDevModeEnabled ? colorConst.devFeature : undefined }}>
          &nbsp; { isDevModeEnabled && `${speedMbit} Mbit/s` }
        </var>
      </Stack>

      {tasks.map((task, i) => (
        <ProgressCard task={task} isDevMode={isDevModeEnabled} key={i} />
      ))}

      <Divider />
    </Box>
  );
}
