import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationCompany, IntegrationProject } from "@services/integrations-service/integrations-service-types";
import { AutodeskHubsResponse, AutodeskProjectsResponse } from "@services/integrations-service/autodesk/autodesk-types";
import { BaseService } from "@services/integrations-service/base-service";
import { StatusCodes } from "http-status-codes";

/** Service that provides methods to manage the Autodesk 3rd party integration */
export class AutodeskService extends BaseService {
  protected integrationId = SphereDashboardAPITypes.IntegrationId.autodesk;

  /**
   * @returns the IntegrationCompany entities of the user
   * @throws {Error} if it fails to get the entities
   */
  public async getIntegrationCompanies(): Promise<IntegrationCompany[]> {
    const { data } = await this.getAutodeskHubs();

    return data.map((autodeskHub) => ({
      integrationId: SphereDashboardAPITypes.IntegrationId.autodesk,
      id: autodeskHub.id,
      name: autodeskHub.attributes.name,
    }));
  }

  /**
   * @returns the IntegrationProject entities for a given integration company
   * @throws {Error} if it fails to get the entities
   * @param companyId ID of the company
   */
  public async getIntegrationProjects(
    companyId: string
  ): Promise<IntegrationProject[]> {
    const { data } = await this.getAutodeskProjects(companyId);

    const integrationProjects: IntegrationProject[] = data.map((autodeskProject) => {
      const issuesContainerId = autodeskProject.relationships.issues.data.id;
      const issuesBcfProjectId = `${issuesContainerId}~${autodeskProject.id}~${companyId}`;
      const rfisContainerId = autodeskProject.relationships.rfis.data.id;
      const rfisBcfProjectId = `${rfisContainerId}~${autodeskProject.id}~${companyId}`;

      return {
        integrationId: SphereDashboardAPITypes.IntegrationId.autodesk,
        id: autodeskProject.id,
        name: autodeskProject.attributes.name,
        companyId,
        rfisBcfProjectId,
        issuesOrObservationsBcfProjectId: issuesBcfProjectId,
        type: autodeskProject.attributes.extension.data.projectType,
      };
    });

    return integrationProjects;
  }

  /**
   * @returns the hubs of the user
   * @throws {Error} if it fails to fetch the hubs
   */
  private async getAutodeskHubs(): Promise<AutodeskHubsResponse> {
    return this.request({
      url: "hubs?filter[extension.type]=hubs:autodesk.bim360:Account",
      verb: "GET",
    });
  }

  /**
   * @returns the projects for a given hub
   * @throws {Error} if it fails to fetch the projects
   * @param hubId ID of the hub
   */
  private async getAutodeskProjects(hubId: string): Promise<AutodeskProjectsResponse> {
    return this.request({
      url: `hubs/${hubId}/projects`,
      verb: "GET",
    });
  }

  /**
   * Handles errors originating from the CoreAPI proxy backend for requests issued to the Autodesk API.
   * - If the error status is UNAUTHORIZED, FORBIDDEN or CONFLICT it attempts to update the token.
   * @param error Error to handle
   * @throws {CoreAPITypes.IBaseResponse} if it can't handle the error.
   */
  protected async handleIntegrationError(error: CoreAPITypes.IBaseResponse): Promise<void> {
    switch (error.status) {
      case StatusCodes.UNAUTHORIZED:
      case StatusCodes.FORBIDDEN:
      case StatusCodes.CONFLICT:
        await this.handleTokenUpdate();
        break;
      default:
        throw error;
    }
  }
}
