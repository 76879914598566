import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { ForbiddenPage } from "@pages/forbidden-page";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { useAppParams } from "@router/router-helper";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import {
  fetchingTeamsFlagsSelector,
  getTeamByIdSelector,
  selectedTeamAsMemberSelector,
} from "@store/teams/teams-selector";
import { TeamTabs } from "@router/route-params";
import { NotFoundPage } from "@pages/not-found-page";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { TeamMembers } from "@pages/members/teams/team-members";
import { useEffect } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import {
  fetchTeamAsMember,
  fetchTeamDetails,
} from "@store/teams/teams-slice-thunk";
import { FetchingStatus } from "@store/store-types";
import { TeamDetailsButton } from "@pages/members/teams/team-details-button";
import { MemberOverviewPage } from "@pages/profile/member-overview-page";
import { resetTeamState } from "@store/teams/teams-slice";
import { isBetaTestingEnabledOrNotProdSelector } from "@store/app/app-selector";

/** Renders the team page */
export function TeamPage(): JSX.Element {
  const { teamId, teamTabs } = useAppParams();
  const { canViewTeams, canEditTeams } = useHasUserValidRoleCompanyLevel();
  const selectedTeam = useAppSelector(getTeamByIdSelector(teamId));
  const selectedTeamAsMember = useAppSelector(selectedTeamAsMemberSelector);
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { status } = useAppSelector(fetchingTeamsFlagsSelector);
  const isBetaTestingEnabled = useAppSelector(
    isBetaTestingEnabledOrNotProdSelector
  );
  
  /** Fetches the required data to display the team details */
  useEffect(() => {
    if (teamId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(fetchTeamDetails({ coreApiClient, teamId }));
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(fetchTeamAsMember({ coreApiClient, teamId }));
    }

    return () => {
      dispatch(resetTeamState());
    };
  }, [coreApiClient, dispatch, teamId]);

  if (!isBetaTestingEnabled && !canViewTeams) {
    return <ForbiddenPage />;
  }

  if (!teamTabs || !Object.values(TeamTabs).includes(teamTabs)) {
    return <NotFoundPage />;
  }

  return (
    <TabsWithRoutes
      selectedTab={teamTabs}
      trackingEventPageName={OpenTabEvents.openTeamTab}
      requiredAttribute={selectedTeam}
      tabs={[
        {
          label: "Members",
          route: TeamTabs.members,
          content: (team) => <TeamMembers team={team} />,
          loadingContent: <div />,
          requiredRoleCompanyLevel: RequiredRoleCompanyLevelName.canViewTeams,
          actionButtons: canEditTeams ? <TeamDetailsButton /> : undefined,
        },
        {
          label: "Groups & Projects",
          route: TeamTabs.projects,
          content: () => (
            <MemberOverviewPage
              member={selectedTeamAsMember}
              isLoading={status === FetchingStatus.pending}
              subjectType="team"
            />
          ),
          loadingContent: <MemberOverviewPage member={null} isLoading={true} subjectType="team" />,
        },
      ]}
    />
  );
}
