import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useCoreApiTokenProvider } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { useMemo } from "react";
import { StagingAreaApiClient } from "@api/stagingarea-api/stagingarea-api";

/**
 * Custom hook that initializes the Staging Area API client and returns an instance of it.
 * Because import-data may in theory have a `null` projectId, we currently allow it here,
 * and return `null` instead of a client in that case.
 */
export function useStagingAreaApiClient({
  projectId,
}: BaseProjectIdProps): StagingAreaApiClient {
  const tokenProvider = useCoreApiTokenProvider({
    coreApiBaseUrl: runtimeConfig.urls.apiBaseUrl,
    projectId: projectId.toString(),
  });

  // Make sure the client is only created once, unless projectId or token provider change.
  return useMemo(
    () =>
      new StagingAreaApiClient({
        baseUrl: runtimeConfig.urls.stagingAreaApiUrl,
        projectId: projectId.toString(),
        tokenProvider,
      }),
    [projectId, tokenProvider]
  );
}
