import { EmptyPage } from "@components/common/empty-page/empty-page";
import {
  TEAM_DISPLAY_NAME,
  TEAMS_DISPLAY_NAME,
} from "@src/constants/team-constants";
import { FetchingStatus } from "@store/store-types";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import MembersIcon from "@assets/icons/members-duo-color.svg?react";
import { CreateTeam } from "@pages/members/teams/create-team";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { useAppSelector } from "@store/store-helper";
import { searchSelector } from "@store/ui/ui-selector";
import BrowseDuoColorIcon from "@assets/icons/browse-duo-color.svg?react";

interface Props {
  /** The fetching status of the teams */
  status: FetchingStatus;
}

/** Empty page content for teams table */
export function TeamsEmptyPage({ status }: Props): JSX.Element {
  const { searchText } = useAppSelector(searchSelector);
  const { canCreateNewTeam } = useHasUserValidRoleCompanyLevel();

  if (searchText) {
    return (
      <EmptyPage
        title="No results found"
        subtitle={`There are no ${TEAMS_DISPLAY_NAME} that match this search.`}
        icon={BrowseDuoColorIcon}
      />
    );
  }

  if (status === FetchingStatus.rejected) {
    return (
      <EmptyPage
        title="Error"
        subtitle={`Failed to fetch the ${TEAMS_DISPLAY_NAME}. Please reload the page and try again.`}
        icon={FailedIcon}
      />
    );
  }

  return (
    <EmptyPage
      title={`You don't have any ${TEAMS_DISPLAY_NAME} yet`}
      // eslint-disable-next-line max-len -- This is a string template
      subtitle={`You haven't added ${TEAMS_DISPLAY_NAME} to your workspace. Select the "new ${TEAM_DISPLAY_NAME}" button to create one.`}
      icon={MembersIcon}
      button={
        canCreateNewTeam && <CreateTeam shouldUseContainedButton={false} />
      }
    />
  );
}
