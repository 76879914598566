import { useRouteMatch } from "@hooks/route-match/use-route-match";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { useDebounce } from "@utils/time-utils";
import {
  LogEventParams,
  useTrackEvent,
} from "@utils/track-event/use-track-event";
import { useEffect, useState } from "react";

/** Debounce interval in milliseconds */
export const SEARCH_DEBOUNCE_TIME = 300;

/** A hook that returns the search placeholder based on the route */
export function useSearchPlaceholder(): string {
  const routes = useRouteMatch();

  switch (true) {
    case routes.isProjectsRoute:
      return "Search for project";
    case routes.isMembersRoute:
      return "Search for member";
    case routes.isGroupsRoute:
      return "Search for group";
    case routes.isTeamsRoute:
      return `Search for ${TEAM_DISPLAY_NAME}`;

    default:
      return "Search";
  }
}

interface DebouncedSearchTextProps {
  /** The text to be searched for */
  searchText: string;

  /** The related event to log */
  logEvent?: LogEventParams;
}

interface DebouncedSearchTextReturn {
  /** Whether it is debouncing to start the search */
  isDebouncingSearch: boolean;

  /** The debounced searched text */
  debouncedSearchText: string;
}

/** Returns the debounced search text and whether it is currently in debounce situation or not */
export function useDebouncedSearchText({
  searchText,
  logEvent,
}: DebouncedSearchTextProps): DebouncedSearchTextReturn {
  const { trackEvent } = useTrackEvent();

  /**
   * Flag whether it is debouncing (waiting) to start the call to search.
   * If true, it means that the subject will be searched again soon, but an indication
   * could be shown already (e.g. a loading spinner).
   */
  const [isDebouncingSearch, setIsDebouncingSearch] = useState(false);

  /** Uses useDebounce hook to react to changes on the search input text */
  const debouncedSearchText = useDebounce(
    searchText,
    SEARCH_DEBOUNCE_TIME,
    () => {
      setIsDebouncingSearch(true);
    },
    () => {
      // The time chosen is small enough for the user not to perceive it as a delay,
      // But large enough to wait for 3 repaint cycles in the browser.
      const WAIT_TIME = 50;
      // We do not immediately set isDebouncingSearch to false.
      // That way the spinner is not immediately hidden preventing a flickering effect in the spinner,
      // when another process wants to show it.
      // By doing this wait, the spinner animation should looks smoother,
      // otherwise it disappears for an instance and then appears again, looking glitchy.
      setTimeout(() => {
        setIsDebouncingSearch(false);
      }, WAIT_TIME);
    }
  );

  // Tracks if the user searches for a project
  useEffect(() => {
    if (debouncedSearchText && logEvent) {
      trackEvent(logEvent);
    }
  }, [debouncedSearchText, logEvent, trackEvent]);

  return {
    isDebouncingSearch,
    debouncedSearchText,
  };
}
