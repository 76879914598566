import ArchiveIcon from "@assets/icons/Archive.svg?react";
import UnarchiveIcon from "@assets/icons/Unarchive.svg?react";
import ShareIcon from "@assets/icons/Share.svg?react";
import { useAppSelector } from "@store/store-helper";
import {
  isProjectActiveSelector,
  isProjectArchivingAllowedSelector,
  isProjectUnarchivingAllowedSelector,
} from "@store/projects/projects-selector";
import { useProjectArchiveDialog } from "@hooks/projects/use-project-archive-dialog";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useDialog } from "@components/common/dialog/dialog-provider";
import {
  SHARE_PROJECT_DIALOG_PROVIDER_PROPS,
  ShareProject,
} from "@components/project/share-project";
import { DeleteProject } from "@pages/project-details/project-overview/delete-project";
import { ActionButtonContainer } from "@components/action-buttons/action-button-container";
import { BaseProjectProps } from "@custom-types/project-types";
import { useMemo } from "react";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { trackShareProject } from "@utils/project-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useAppParams } from "@router/router-helper";
import { useHasValidFeatureProjectLevel } from "@hooks/feature-control/use-has-user-valid-feature-project-level";

/**
 * List of all the buttons that will be shown on project overview tab
 */
export function ProjectOverviewActionButtons({
  project,
}: BaseProjectProps): JSX.Element {
  const isArchivingAllowed = useAppSelector(
    isProjectArchivingAllowedSelector(project)
  );
  const isUnarchivingAllowed = useAppSelector(
    isProjectUnarchivingAllowedSelector(project)
  );
  const { createDialog } = useDialog();
  const { trackEvent } = useTrackEvent();
  const { projectTabs } = useAppParams();

  const isProjectActive = useAppSelector(isProjectActiveSelector(project.id));
  const { canUnarchiveProjects, canDeleteProject } =
    useHasUserValidRoleProjectLevel({ selectedProject: project });

  const archiveOrUnarchiveProject = useProjectArchiveDialog({ project });

  const { canArchiveProject } = useHasValidFeatureProjectLevel();

  const isAllowedToArchiveOrUnarchive = useMemo(
    () => (isProjectActive ? canArchiveProject : canUnarchiveProjects),

    [canArchiveProject, canUnarchiveProjects, isProjectActive]
  );

  return (
    <>
      {/* Button to Archive / Unarchive project */}
      {isAllowedToArchiveOrUnarchive && (
        <ActionButtonContainer>
          <SphereTooltip
            title={`${isProjectActive ? "Archive" : "Unarchive"} project`}
          >
            <FaroIconButton
              isDisabled={
                isProjectActive ? !isArchivingAllowed : !isUnarchivingAllowed
              }
              component={isProjectActive ? ArchiveIcon : UnarchiveIcon}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
              onClick={() => archiveOrUnarchiveProject()}
            />
          </SphereTooltip>
        </ActionButtonContainer>
      )}

      {/* Button to share a link to the project */}
      <ActionButtonContainer>
        <SphereTooltip
          title="Share project"
          boxProps={{
            sx: {},
          }}
        >
          <FaroIconButton
            component={ShareIcon}
            onClick={() => {
              trackShareProject({
                ...project,
                eventLocation: `project ${projectTabs} page`,
                trackEvent,
              });
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
              createDialog(
                SHARE_PROJECT_DIALOG_PROVIDER_PROPS,
                <ShareProject projectId={project.id} />
              );
            }}
          />
        </SphereTooltip>
      </ActionButtonContainer>

      {/* Button to delete a project */}
      {!isProjectActive && canDeleteProject && (
        <ActionButtonContainer>
          <DeleteProject project={project} />
        </ActionButtonContainer>
      )}
    </>
  );
}
