import { HandleableError } from "@context-providers/error-boundary/error-types";
import * as Sentry from "@sentry/react";

interface Props {
  /** The actual error object */
  error: HandleableError;

  /** The error title */
  title?: string;

  /** Additional data to be attached to error */
  data?: Record<string, unknown>;
}

/**
 * Capturing Errors and Events in Sentry.
 * A simple text should be passed as message, additional data should be passed as data
 * https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-825887112
 */
export function sentryCaptureError({ error, title, data }: Props): void {
  Sentry.captureException(error, (scope) => {
    if (title) {
      return scope.addBreadcrumb({
        type: "error",
        category: "error",
        level: "error",
        message: title,
      });
    }
    if (data) {
      return scope.setContext("extra-data", data);
    }
    return scope;
  });
}
