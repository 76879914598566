import { GUID, TypedEvent } from "@faro-lotv/foundation";
import { ApiClient, APITypes } from "@stellar/api-logic";
import { CoreFileUploader } from "@utils/core-file-uploader";

/**
 * An object to manage a specific CoreFileUploader object and to connect it to the
 * object managing all uploads
 */
export class UploadController {
  uploader: CoreFileUploader;
  abortController = new AbortController();

  uploadCompleted = new TypedEvent<{
    id: GUID;
    downloadUrl: string;
    md5: string;
  }>();

  uploadFailed = new TypedEvent<{ id: GUID; fileName: string; error: Error }>();

  uploadUpdated = new TypedEvent<{
    id: GUID;
    progress: number;
    expectedEnd: number;
    speedMbit: number;
  }>();

  uploadCanceled = new TypedEvent<{ id: GUID; fileName: string }>();

  constructor(
    public id: GUID,
    file: File,
    projectId: APITypes.ProjectId,
    coreApi: ApiClient
  ) {
    this.uploader = new CoreFileUploader(file, projectId, coreApi);
    this.uploader.uploadCompleted.on(({ downloadUrl, md5 }) => {
      this.uploadCompleted.emit({
        id: this.id,
        downloadUrl,
        md5,
      });
    });
    this.uploader.uploadFailed.on((error: Error) => {
      this.uploadFailed.emit({ id: this.id, fileName: file.name, error });
    });
    this.uploader.progressChanged.on(({ percentage, expectedEnd, speedMbit }) => {
      this.uploadUpdated.emit({
        id: this.id,
        progress: percentage,
        expectedEnd,
        speedMbit,
      });
    });
    this.uploader.uploadCanceled.on(() => {
      this.uploadCanceled.emit({
        id: this.id,
        fileName: file.name,
      });
    });
  }
}
