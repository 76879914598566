import { getHBDashboardUrl } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-utils";
import { SidebarHolobuilderTooltip } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-tooltip";
import { Box, List, Stack, tooltipClasses } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "@store/store-helper";
import { isOpenInHBButtonEnabledSelector } from "@store/sdb-company/sdb-company-selector";
import { SidebarEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroSwitch } from "@components/common/faro-switch";
import { SendFeedbackDialog } from "@components/header/send-feedback-dialog";
import { sphereColors } from "@styles/common-colors";
import { FontWeights } from "@faro-lotv/flat-ui";
import { withEllipsis } from "@styles/common-styles";

interface Props {
  /** Flag whether the sidebar should be shown full size or minified */
  isSidebarFullSize: boolean;
}

/** Renders a sidebar item that navigates to the HoloBuilder dashboard */
export function SidebarHolobuilderItem({
  isSidebarFullSize,
}: Props): JSX.Element | null {
  const { trackAsyncEvent } = useTrackEvent();

  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(true);
  const [shouldShowFeedbackDialog, setShouldShowFeedbackDialog] =
    useState<boolean>(false);
  const isOpenInHBButtonEnabled = useAppSelector(
    isOpenInHBButtonEnabledSelector
  );

  async function navigateToHBDashboard(): Promise<void> {
    await trackAsyncEvent({
      name: SidebarEvents.navigateToHBDashboard,
    });

    const hbUrl = getHBDashboardUrl();
    window.location.assign(hbUrl);
  }

  async function handleSwitchChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const willBeChecked = event.target.checked;

    await trackAsyncEvent({
      name: SidebarEvents.toggleSDBAccess,
      props: {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Amplitude event property
        UseNewDashboard: willBeChecked,
      },
    });

    if (!willBeChecked) {
      setShouldShowFeedbackDialog(true);
    }

    setIsSwitchChecked(willBeChecked);
  }

  if (!isOpenInHBButtonEnabled) {
    return null;
  }

  return (
    <List
      sx={{
        paddingY: 0,
        mb: "24px",
      }}
    >
      <SphereTooltip
        title={<SidebarHolobuilderTooltip />}
        tooltipProps={{
          sx: {
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: 320,
            },
          },
        }}
      >
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            cursor: "pointer",
            pl: "18px",
            pr: "2px",
          }}
        >
          {isSidebarFullSize && (
            <Stack
              sx={{
                ...withEllipsis,
              }}
            >
              <Box
                sx={{
                  color: sphereColors.gray800,
                  fontSize: "10px",
                  lineHeight: "14px",
                }}
              >
                Try the
              </Box>
              <Box
                sx={{
                  color: sphereColors.gray800,
                  fontSize: "12px",
                  fontWeight: FontWeights.Bold,
                  letterSpacing: "0.48px",
                  textTransform: "uppercase",
                  ...withEllipsis,
                }}
              >
                New Dashboard
              </Box>
            </Stack>
          )}
          <FaroSwitch
            checked={isSwitchChecked}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            onChange={handleSwitchChange}
            sx={{ position: "relative", top: "6px" }}
          />
        </Stack>
      </SphereTooltip>

      <SendFeedbackDialog
        shouldShowFeedbackDialog={shouldShowFeedbackDialog}
        setShouldShowFeedbackDialog={setShouldShowFeedbackDialog}
        featureName="Switch to HB Dashboard"
        confirmText="Submit and switch back"
        cancelText="Switch without feedback"
        feedbackQuestionText="Before you switch back, can you share some feedback?"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onSubmitFeedback={navigateToHBDashboard}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onCancelFeedback={navigateToHBDashboard}
        onCloseFeedback={() => setIsSwitchChecked(true)}
      />
    </List>
  );
}
