import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { BaseGroupIdProps } from "@custom-types/sdb-company-types";
import { useAppParams } from "@router/router-helper";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";

interface UseMemberGroupDetails {
  /** Details of a group that the member belongs to */
  groupDetails: SphereDashboardAPITypes.ICompanyMemberGroupDetails | null;

  /** Whether the group details are currently being fetched */
  isFetchingGroupDetails: boolean;
}

/** Fetches the details of group of a company member */
export function useMemberGroupDetails({
  groupId,
}: BaseGroupIdProps): UseMemberGroupDetails {
  const coreApiClient = useCoreApiClient();
  const { companyId, memberId, teamId } = useAppParams();
  const { handleErrorWithToast } = useErrorContext();

  const [isFetchingGroupDetails, setIsFetchingGroupDetails] =
    useState<boolean>(false);
  const [groupDetails, setGroupDetails] =
    useState<SphereDashboardAPITypes.ICompanyMemberGroupDetails | null>(null);

  useEffect(() => {
    async function fetchMemberGroupDetails(): Promise<void> {
      // Fetch group details only if the member or team id is available
      const id = memberId || teamId;
      if (companyId && id) {
        setIsFetchingGroupDetails(true);

        try {
          const data = await coreApiClient.V3.SDB.getCompanyMemberGroupDetails(
            companyId,
            id,
            groupId
          );

          setGroupDetails(data);
        } catch (error) {
          handleErrorWithToast({
            id: `getCompanyMemberGroupDetails-${Date.now().toString()}`,
            title: "Cannot fetch workspace member group details",
            error,
          });
        }

        setIsFetchingGroupDetails(false);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    fetchMemberGroupDetails();
  }, [
    companyId,
    coreApiClient,
    groupId,
    handleErrorWithToast,
    memberId,
    teamId,
  ]);

  return { groupDetails, isFetchingGroupDetails };
}
