import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { updateTeamDetails } from "@store/teams/teams-slice-thunk";
import { useAppDispatch } from "@store/store-helper";
import { BaseTeamProps } from "@custom-types/teams-types";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useState } from "react";
import { isValidTeamName } from "@utils/team-utils";

interface Props extends BaseTeamProps {
  /** True if the team name should be editable */
  isEditable: boolean;
}

/** Renders and updates the team name */
export function EditableTeamName({ team, isEditable }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const [hasError, setHasError] = useState<boolean>(false);

  /**
   * Updates the team name in the backend and returns the new value if successful.
   * Otherwise, returns the old value.
   *
   * @param value The new team name.
   */
  async function onUpdateName(value: string): Promise<string> {
    trackEvent({
      name: TeamEvents.changeTeamDetails,
      props: { newNameLength: value.length, attribute: "name" },
    });

    try {
      await dispatch(
        updateTeamDetails({
          coreApiClient,
          teamId: team.id,
          payload: { name: value },
        })
      );
      return value;
    } catch (error) {
      return team.name ?? "";
    }
  }

  function validateTeamName(inputValue: string): boolean {
    if (isValidTeamName(inputValue)) {
      setHasError(false);
      return true;
    }

    setHasError(true);
    return false;
  }

  return (
    <FaroTextField
      label={capitalizeFirstLetter(TEAM_DISPLAY_NAME)}
      initialValue={team.name}
      faroVariant="main"
      fontSize="18px"
      isReadOnly={!isEditable}
      shouldShowTooltip
      minInputLength={2}
      onConfirmed={(value) => onUpdateName(value.trim())}
      onCancel={() => setHasError(false)}
      validate={validateTeamName}
      hasError={hasError}
      helperText={hasError ? "Shouldn't contain (,) or (;)" : ""}
      shouldShowCharacterCount={true}
      maxInputLength={50}
    />
  );
}
