import {
  GridColDef,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { FormatDate } from "@hooks/use-date-time";
import { TeamName } from "@components/table/teams/team-name";
import { DateTime } from "luxon";
import { TeamsMembersColumn } from "@components/table/teams/teams-members-column";
import { TeamsTableAction } from "@components/table/teams/teams-table-action";
import { mapTeamMembersToSdbMembers } from "@components/table/teams/teams-table-utils";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { formatUserRoleType } from "@utils/data-display";
import { RoleColumn } from "@components/table/members/members-column/role-column/role-column";
import { SdbTeam } from "@custom-types/teams-types";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";

interface Props extends BaseCompanyIdProps {
  /** The function to format date. Needs to be passed since getMembersColumns is not a hook */
  formatDate: FormatDate;
}

/** Return all the possible columns for Teams table */
export function getTeamsColumns({
  companyId,
  formatDate,
}: Props): Record<TeamsHeaders, GridColDef> {
  return {
    [TeamsHeaders.name]: {
      field: TeamsHeaders.name,
      flex: 1,
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<{ entity: SdbTeam }>) => {
        return <TeamName team={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: SdbTeam }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },
    [TeamsHeaders.users]: {
      field: TeamsHeaders.users,
      flex: 1,
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<{ entity: SdbTeam }>) => {
        return (
          <TeamsMembersColumn
            members={mapTeamMembersToSdbMembers(
              params.row.entity.sampleMembers
            )}
            membersCount={params.row.entity.memberCount}
          />
        );
      },
      valueGetter: (_, row: { entity: SdbTeam }) => row.entity.memberCount,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TeamsHeaders.creationDate]: {
      field: TeamsHeaders.creationDate,
      flex: 0.75,
      minWidth: 180,
      renderCell: (params: GridRenderCellParams<{ entity: SdbTeam }>) => {
        return (
          <var>
            {formatDate(params.row.entity.createdAt, DateTime.DATETIME_MED)}
          </var>
        );
      },
      valueGetter: (_, row: { entity: SdbTeam }) =>
        new Date(row.entity.createdAt).getTime(),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TeamsHeaders.workspaceRole]: {
      field: TeamsHeaders.workspaceRole,
      flex: 0.75,
      headerName: "Workspace Role",
      minWidth: 180,
      renderCell: (params: GridRenderCellParams<{ entity: SdbTeam }>) => (
        <RoleColumn
          companyId={companyId}
          subjectType="team"
          memberIdentityRole={{
            identity: params.row.entity.identity,
            role: params.row.entity.role,
          }}
        />
      ),

      valueGetter: (_, row: { entity: SdbTeam }) =>
        formatUserRoleType(row.entity.role),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TeamsHeaders.options]: {
      field: TeamsHeaders.options,
      type: "actions",
      align: "right",
      flex: 0.5,
      minWidth: 180,
      renderCell: (params: GridRenderCellParams<{ entity: SdbTeam }>) => {
        return <TeamsTableAction team={params.row.entity} />;
      },
    },
  };
}
