/**
 * The progress API client code is mostly copied from the Sphere Viewer app client:
 * https://dev.azure.com/faro-connect/ViewerApp/_git/LotvMonorepo?path=/apps/sphere-web-viewer/src/apis/progress-api
 */

import { TokenManager } from "@faro-lotv/service-wires";
import {
  BackgroundTaskType,
  ProgressApiLatestStatusResponse,
} from "@api/progress-api/progress-api-types";
import { isProgressApiLatestStatusResponse } from "@api/progress-api/progress-api-type-guards";
import { CLIENT_ID } from "@api/client-id";

/** Number of task items per page that we will fetch from the ProgressAPI */
const ITEMS_PER_PAGE = 30;

type QueryParams = Pick<ProgressApiLatestStatusResponse, "before"> & {
  /** Name of the task type to query */
  taskType?: BackgroundTaskType;
};

/**
 * A class to handle the queries regarding long running background tasks
 */
export class ProgressApiClient {
  /**
   * @param projectId Id of the current project
   * @param tokenManager To get tokens for this project
   * @param baseUrl The base url of the progress api
   */
  constructor(
    private projectId: string,
    private tokenManager: TokenManager,
    private baseUrl: string
  ) {}

  /**
   * @returns the state of the backend tasks
   */
  async requestProgress({
    before,
    taskType,
  }: QueryParams): Promise<ProgressApiLatestStatusResponse> {
    const url = new URL(
      `${this.baseUrl}/v1/status/latest?ProjectId=${this.projectId}&pageSize=${ITEMS_PER_PAGE}`
    );

    if (before) {
      url.searchParams.append("before", before);
    } else {
      url.searchParams.append("last", "true");
    }

    if (taskType) {
      url.searchParams.append("taskType", taskType);
    }

    const req = await fetch(url.toString(), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await this.tokenManager.getToken()}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "X-Holobuilder-Component": CLIENT_ID,
      },
    });
    if (!req.ok) {
      throw new Error(`Unable to talk to ProgressAPI: ${req.statusText}`);
    }

    const response = await req.json();

    if (!isProgressApiLatestStatusResponse(response)) {
      throw new Error("ProgressAPI returned invalid response", {
        cause: response,
      });
    }

    return response;
  }
}
