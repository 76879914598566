import { TeamsTable } from "@components/table/teams/teams-table";
import { TeamsDetails } from "@pages/members/teams/teams-details";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import {
  fetchingTeamsFlagsSelector,
  teamsSelector,
} from "@store/teams/teams-selector";
import { useEffect, useMemo } from "react";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { searchSelector } from "@store/ui/ui-selector";
import { SearchbarEvents } from "@utils/track-event/track-event-list";
import { useAppParams } from "@router/router-helper";
import { TeamsPageBulkActions } from "@pages/members/teams/teams-page-bulk-actions";
import { NotFoundPage } from "@pages/not-found-page";
import { useCoreApiClient } from "@api/use-core-api-client";
import { fetchTeams } from "@store/teams/teams-slice-thunk";
import { useDebouncedSearchText } from "@hooks/use-search";
import { TeamsEmptyPage } from "@pages/members/teams/teams-empty-page";

/**
 * The component that will render the kpi details and the table for the teams
 */
export function Teams(): JSX.Element {
  const { companyId } = useAppParams();
  const teams = useAppSelector(teamsSelector);
  const { status } = useAppSelector(fetchingTeamsFlagsSelector);
  const { searchText } = useAppSelector(searchSelector);
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  /** Uses useDebounce hook to react to changes on the search input text */
  const { isDebouncingSearch, debouncedSearchText } = useDebouncedSearchText({
    searchText,
    logEvent: {
      name: SearchbarEvents.searchForTeam,
    },
  });

  // Constructing the empty page content if should show
  const shouldShowEmptyPage = useMemo(() => {
    return (
      (teams.length === 0 && status === FetchingStatus.succeeded) ||
      status === FetchingStatus.rejected
    );
  }, [status, teams.length]);

  // Fetches the searched teams
  useEffect(() => {
    async function getSearchedTeams(): Promise<void> {
      await dispatch(
        fetchTeams({
          coreApiClient,
          searchText: debouncedSearchText,
        })
      );
    }
    if (searchText) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      getSearchedTeams();
    }
  }, [coreApiClient, dispatch, debouncedSearchText, searchText]);

  if (!companyId) {
    return <NotFoundPage />;
  }

  const buttonComponents = TeamsPageBulkActions({
    companyId,
    teams,
  });

  // If fetching is done and teams are empty, then either no team is present or the search text is not matching any team
  if (shouldShowEmptyPage) {
    return <TeamsEmptyPage status={status} />;
  }

  return (
    <>
      <TeamsDetails />

      <TeamsTable
        companyId={companyId}
        teams={teams}
        isLoading={status === FetchingStatus.pending || isDebouncingSearch}
        requiredColumns={[
          TeamsHeaders.name,
          TeamsHeaders.users,
          TeamsHeaders.creationDate,
          TeamsHeaders.workspaceRole,
          TeamsHeaders.options,
        ]}
        bulkActionButtons={buttonComponents}
      />
    </>
  );
}
