import { PropsWithChildren } from "react";
import { Box } from "@mui/material";

interface Props {
  /** Data test id for testing */
  dataTestId?: string;
}

/**
 * A container for the tiles, for example the projects and groups tiles. It makes sure that the tiles are
 * aligned properly.
 */
export function TilesContainer({
  children,
  dataTestId,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <Box
      component="div"
      data-testid={dataTestId}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        columnGap: "20px",
      }}
    >
      {children}
    </Box>
  );
}
