import {
  MemberBulkButtonComponents,
  MemberTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { BulkSendEmail } from "@components/table/members/bulk-actions/bulk-send-email";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { BulkMemberCsvExport } from "@components/table/members/bulk-actions/bulk-member-csv-export";
import { BaseMembersProps } from "@custom-types/member-types";
import { BulkResendInvitation } from "@components/table/members/bulk-actions/bulk-resend-invitation";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { BulkChangeCompanyRole } from "@components/table/members/bulk-actions/bulk-change-company-role";
import { BulkRemoveMember } from "@components/table/members/bulk-actions/bulk-remove-member";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { useMemo } from "react";
import { isMemberActive } from "@utils/user-utils";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props extends BaseMembersProps {
  /** The details of the selected company in which bulk actions are shown */
  company: SdbCompany | null;
}

/** List all the bulk action components for company member table */
export function CompanyMemberBulkActions({
  members,
  company,
}: Props): MemberBulkButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));
  const isAnyRowSelected = selectedEntities.length !== 0;
  const isAnyMemberPending = selectedEntities.some(
    (member) => !isMemberActive(member)
  );
  const { canEditMember, canDeleteMemberFromCompany } =
    useHasUserValidRoleCompanyLevel();

  const allowedButtonsBasedOnRowSelection: MemberTableBulkName[] =
    useMemo(() => {
      const allowedButtons: MemberTableBulkName[] = ["exportCsv"];

      if (isAnyRowSelected) {
        allowedButtons.push("sendEmail");

        if (canEditMember) {
          allowedButtons.push("changeRole");
        }

        if (isAnyMemberPending) {
          allowedButtons.push("resendInvitation");
        }

        if (canDeleteMemberFromCompany) {
          allowedButtons.push("removeMember");
        }
      }

      return allowedButtons;
    }, [
      canDeleteMemberFromCompany,
      canEditMember,
      isAnyMemberPending,
      isAnyRowSelected,
    ]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  if (!company) {
    return {
      changeRole: null,
      resendInvitation: null,
      removeMember: null,
      sendEmail: null,
      exportCsv: null,
    };
  }

  return {
    changeRole: shouldShowBulkButtons("changeRole") ? (
      <BulkChangeCompanyRole companyId={company.id} />
    ) : null,

    resendInvitation: shouldShowBulkButtons("resendInvitation") ? (
      <BulkResendInvitation
        selectedEntities={selectedEntities}
        companyId={company.id}
        subjectType="workspace"
      />
    ) : null,

    removeMember: shouldShowBulkButtons("removeMember") ? (
      <BulkRemoveMember subjectType="workspace" companyId={company.id} />
    ) : null,

    sendEmail: shouldShowBulkButtons("sendEmail") ? (
      <BulkSendEmail selectedEntities={selectedEntities} />
    ) : null,

    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <BulkMemberCsvExport
        selectedEntities={isAnyRowSelected ? selectedEntities : members}
        subjectType="workspace"
        entityName={company.name}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
