import {
  Breadcrumb,
  BreadcrumbHandleData,
} from "@components/header/faro-breadcrumbs";
import { MainRoutes, RouteParams, UsersTabs } from "@router/route-params";
import { commonStrings } from "@assets/common-strings";
import { getPrettyName } from "@utils/user-utils";
import { hasUserValidRoleCompanyLevel } from "@utils/access-control/company/company-access-control";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";

/** Breadcrumbs can provide a callback to change their content dynamically */
export type BreadcrumbCallback = (
  params: Partial<RouteParams>,
  data: BreadcrumbHandleData
) => Breadcrumb | Breadcrumb[] | null;

/** Single object for a breadcrumb handle element. */
export interface BreadcrumbHandle {
  /**
   * Could be a direct breadcrumb or a function that returns a breadcrumb,
   * for example to include data from loaders or to include the selected project.
   */
  breadcrumb?: Breadcrumb | BreadcrumbCallback;
}

/** Returns the breadcrumb handle for the route "/projects" */
export function getProjectsBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>) => {
      return {
        text: commonStrings.PROJECTS,
        link: `/${params.companyId}/${MainRoutes.projects}`,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/projects/:projectStatus/:projectId" */
export function getProjectBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>, data: BreadcrumbHandleData) => {
      return {
        text: data?.selectedProject?.name ?? params.projectId ?? "",
        shouldNotTranslate: true,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/members" */
export function getMembersBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>, data: BreadcrumbHandleData) => {
      const canViewAllCompanyUsers = hasUserValidRoleCompanyLevel({
        roleName: RequiredRoleCompanyLevelName.canViewAllCompanyUsers,
        currentUser: data.currentUser ?? null,
      });
      return {
        text: commonStrings.MEMBERS,
        link: `/${params.companyId}/${MainRoutes.members}`,
        isDisabled: !canViewAllCompanyUsers,
      };
    },
  };
}

/**
 * Returns the breadcrumb handle for the route "/members/:memberId"
 */
export function getMemberProfileBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>, data: BreadcrumbHandleData) => {
      let text;
      // If the member is the current user, use that object to react to changes for name, etc.
      if (params.memberId && params.memberId === data.currentUser?.identity) {
        text = getPrettyName(data.currentUser);
      } else {
        text = getPrettyName(data.selectedMember);
      }
      if (!text && params.memberId) {
        text = params.memberId;
      }

      return {
        text,
        shouldNotTranslate: true,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/groups" */
export function getGroupsBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>) => {
      return {
        text: commonStrings.GROUPS,
        link: `/${params.companyId}/${MainRoutes.groups}`,
      };
    },
  };
}

/**
 * Returns the breadcrumb handle for the route "/groups/:groupId"
 */
export function getGroupBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>, data: BreadcrumbHandleData) => {
      return {
        text: data?.selectedGroup?.name ?? params.groupId ?? "",
        shouldNotTranslate: true,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/analytics" */
export function getAnalyticsBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>) => {
      return {
        text: commonStrings.ANALYTICS,
        link: `/${params.companyId}/${MainRoutes.analytics}`,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/settings" */
export function getWorkspaceSettingsBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>) => {
      return {
        text: commonStrings.SETTINGS,
        link: `/${params.companyId}/${MainRoutes.settings}`,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/add-ons" */
export function getWorkspaceAddonsBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>) => {
      return {
        text: commonStrings.ADDONS,
        link: `/${params.companyId}/${MainRoutes.addons}`,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/integrations" */
export function getIntegrationsBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>) => {
      return {
        text: commonStrings.INTEGRATIONS,
        link: `/${params.companyId}/${MainRoutes.integrations}`,
      };
    },
  };
}

/** Returns the breadcrumb handle for the route "/user-groups/:teamId" */
export function getTeamBreadcrumbHandle(): BreadcrumbHandle {
  return {
    breadcrumb: (params: Partial<RouteParams>, data: BreadcrumbHandleData) => {
      return [
        {
          text: `${commonStrings.TEAMS}`,
          link: `/${params.companyId}/${MainRoutes.members}/${UsersTabs.teams}`,
        },
        {
          text: data?.selectedTeam?.name ?? params.teamId ?? "",
          link: `/${params.companyId}/${MainRoutes.teams}`,
        },
      ];
    },
  };
}
