import {
  IntegrationProject,
  ProjectIntegrationId,
  ProjectIntegrationsMap,
} from "@services/integrations-service/integrations-service-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  isAutodeskLegacyProjectIntegration,
  isProcoreLegacyProjectIntegration,
  isProjectIntegration,
} from "@services/integrations-service/integrations-type-guards";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { runtimeConfig } from "@src/runtime-config";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { ReactSetStateFunction } from "@custom-types/types";
import { IntegrationsService } from "@services/integrations-service/integrations-service";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { IntegrationDialogTypes, IntegrationSections, ProjectConnectionDialogType } from "@pages/integrations/integrations-types";
import { TokensService } from "@services/integrations-service/tokens-service";
import { useToast } from "@hooks/use-toast";
import { REAUTHORIZATION_NOT_COMPLETED } from "@services/integrations-service/integrations-constants";

interface ReturnProps {
  /** Integrations service instance */
  integrationsService: IntegrationsService;

  /** List of integrations enabled in workspace level and not available in project level */
  enabledWorkspaceIntegrations: SphereDashboardAPITypes.IntegrationId[];

  /** List of integrations enabled in project level (for selected project) */
  enabledProjectIntegrations: SphereDashboardAPITypes.IntegrationId[];

  /** Callback to connect integration to project */
  connectProject: (integrationProject: IntegrationProject) => Promise<void>;

  /** Flag to determine if the integrations is still loading */
  isLoadingIntegrations: boolean;

  /** Indicates whether the error screen should be displayed if an error occurs during data fetching. */
  shouldShowErrorPage: boolean;

  /** Callback to disconnect integration to project */
  disconnectProject: (
    integrationId: SphereDashboardAPITypes.IntegrationId
  ) => Promise<void>;

  /** Get the name of the project integration */
  getProjectIntegrationName: (
    integrationId: SphereDashboardAPITypes.IntegrationId
  ) => string | undefined;

  /** The "Integration authorization" dialog type to display */
  selectedDialog: IntegrationDialogTypes | null;

  /** Callback to cancel the token re-authorization */
  cancelReauthorization: () => void;

  /** The "Connect project" dialog type to display */
  projectDialogType: ProjectConnectionDialogType

  /** Callback to set "Connect project" dialog type to display */
  setProjectDialogType: ReactSetStateFunction<ProjectConnectionDialogType>;
}

/** All the hooks that are used for integration process in project level */
export function useProjectIntegrations({
  projectId,
}: BaseProjectIdProps): ReturnProps {
  const [enabledWorkspaceIntegrations, setEnabledWorkspaceIntegrations] =
    useState<SphereDashboardAPITypes.IntegrationId[]>([]);
  const [enabledProjectIntegrations, setEnabledProjectIntegrations] = useState<
    SphereDashboardAPITypes.IntegrationId[]>([]);
  const [isLoadingProjectIntegrations, setIsLoadingProjectIntegrations] =
    useState<boolean>(true);
  const [isLoadingWorkspaceIntegrations, setIsLoadingWorkspaceIntegrations] =
    useState<boolean>(true);
  const [shouldShowErrorPage, setShouldShowErrorPage] =
    useState<boolean>(false);
  /** Integrations available in project level */
  const [projectIntegrations, setProjectIntegrations] = useState<
    ProjectIntegrationsMap | undefined
  >(undefined);
  /** Selected dialog type for the token re-authorization workflow */
  const [selectedDialog, setSelectedDialog] = useState<IntegrationDialogTypes | null>(null);
  /** Project connection dialog type */
  const [projectDialogType, setProjectDialogType] = useState<ProjectConnectionDialogType>("none");

  const coreApiClient = useCoreApiClient();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const { handleErrorWithToast } = useErrorContext();
  const { createDialog } = useDialog();
  const { showToast } = useToast();

  const tokensService = useMemo(() => new TokensService({ coreApiClient }), [coreApiClient]);

  /**
   * Handles the token re-authorization:
   * 1. Shows a dialog to inform the user that the integration needs to be authorized again
   * 2. After confirmation the authorization workflow initiates. Once the integration is
   * is authorized again the user can resume the project connection workflow.
   * If the reauthorization then both reauthorization and project connection dialogs are closed
   * and an specific error is thrown.
   */
  const reauthorize = useCallback(async (
    integrationId: SphereDashboardAPITypes.IntegrationId
  ): Promise<void> => {
    try {
      await createDialog(
        {
          title: "Re-authorization required",
          isSuccessMessage: true,
        },
        `You need to authorize ${IntegrationSections[integrationId].displayName} again`
      );
      setSelectedDialog("inProgress");

      const updatedTokens = await tokensService.authorizeIntegration(integrationId);
      const updatedTokenIds = updatedTokens.map((token) => token.provider);
      setEnabledWorkspaceIntegrations(updatedTokenIds);

      setSelectedDialog(null);
      showToast({
        message: "Integration successfully re-authorized",
        type: "success",
      });
    } catch (_) {
      setProjectDialogType("none");
      setSelectedDialog(null);
      throw new Error(REAUTHORIZATION_NOT_COMPLETED);
    }
  },
    [createDialog, showToast, tokensService]
  );

  /**
   * Triggers when the user clicks on the "cancel" button during the re-authorization workflow.
   * Closes the authorization window, the "authorization in progress" dialog and the
   * "project connection" dialog.
   */
  const cancelReauthorization = useCallback(() => {
    tokensService.closeAuthorizationWindow();
    setSelectedDialog(null);
    setProjectDialogType("none");

    showToast({
      message: "Unable to continue with the project connection.",
      description: "The integration reauthorization has been canceled.",
      type: "warning",
    });
  }, [showToast, tokensService]);

  const integrationsService = useMemo(() => {
    return new IntegrationsService({
      coreApiClient,
      projectApiClient,
      procoreApiUrl: runtimeConfig.integrations.procoreApiUrl,
      reauthorize,
    });
  }, [coreApiClient, projectApiClient, reauthorize]);

  const isProcoreConnected = useMemo(() => {
    if (!projectIntegrations) {
      return false;
    }

    const isProcoreLegacyConnected = isProcoreLegacyProjectIntegration(
      projectIntegrations[ProjectIntegrationId.procore]
    );
    const isProcoreObservationsConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.procoreObservations]
    );
    const isProcoreRfisConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.procoreRfis]
    );

    return (
      isProcoreLegacyConnected &&
      isProcoreObservationsConnected &&
      isProcoreRfisConnected
    );
  }, [projectIntegrations]);

  const isAutodeskConnected = useMemo(() => {
    if (!projectIntegrations) {
      return false;
    }

    const isAutodeskLegacyConnected = isAutodeskLegacyProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodesk]
    );
    const isAutodeskAccIssuesConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskAccIssues]
    );
    const isAutodeskAccRfisConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskAccRfis]
    );
    const isAutodeskBim360IssuesConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskBim360Issues]
    );
    const isAutodeskBim360RfisConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskBim360Rfis]
    );

    return (
      isAutodeskLegacyConnected &&
      ((isAutodeskAccIssuesConnected && isAutodeskAccRfisConnected) ||
        (isAutodeskBim360IssuesConnected && isAutodeskBim360RfisConnected))
    );
  }, [projectIntegrations]);

  const connectProject = useCallback(
    async (integrationProject: IntegrationProject): Promise<void> => {
      const updatedProjectIntegrations =
        await integrationsService.connectProject(integrationProject);
      setProjectIntegrations(updatedProjectIntegrations);
    },
    [integrationsService]
  );

  const disconnectProject = useCallback(
    async (
      integrationId: SphereDashboardAPITypes.IntegrationId
      // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
    ): Promise<void> => {
      const updatedProjectIntegrations =
        await integrationsService.disconnectProject(integrationId);
      setProjectIntegrations(updatedProjectIntegrations);
    },
    [integrationsService]
  );

  /** Get the list of integrations available in project level */
  useEffect(() => {
    async function getProjectIntegrations(): Promise<void> {
      try {
        const integrations = await integrationsService.getProjectIntegrations();
        setProjectIntegrations(integrations);
      } catch (error) {
        setShouldShowErrorPage(true);

        handleErrorWithToast({
          id: `getProjectIntegrations-${Date.now().toString()}`,
          title: "Failed to get the project integrations.",
          error,
        });
      } finally {
        setIsLoadingProjectIntegrations(false);
      }
    }

    void getProjectIntegrations();
  }, [handleErrorWithToast, integrationsService]);

  /** Get the list of integrations available in user level */
  useEffect(() => {
    async function getIntegrationTokens(): Promise<void> {
      try {
        const integrationTokens = await tokensService.getIntegrationTokens();

        const workspaceIntegrations: SphereDashboardAPITypes.IntegrationId[] =
          integrationTokens.map(({ provider }) => provider);

        setEnabledWorkspaceIntegrations(workspaceIntegrations);
      } catch (error) {
        setShouldShowErrorPage(true);
        handleErrorWithToast({
          id: `getIntegrationTokens-${Date.now().toString()}`,
          title: "Failed to get the integration tokens.",
          error,
        });
      } finally {
        setIsLoadingWorkspaceIntegrations(false);
      }
    }

    void getIntegrationTokens();
  }, [handleErrorWithToast, tokensService]);

  /** Updates the list of enabled integrations to project */
  useEffect(() => {
    const enabledProjectIntegrations: SphereDashboardAPITypes.IntegrationId[] =
      [];
    if (isAutodeskConnected) {
      enabledProjectIntegrations.push(
        SphereDashboardAPITypes.IntegrationId.autodesk
      );
    }
    if (isProcoreConnected) {
      enabledProjectIntegrations.push(
        SphereDashboardAPITypes.IntegrationId.procore
      );
    }
    setEnabledProjectIntegrations(enabledProjectIntegrations);
  }, [isAutodeskConnected, isProcoreConnected]);

  /**
   *  Gets the name of the project associated with a specific integration.
   *  @param integrationId The ID of the integration for which the project name is required.
   *  @returns Name of the integrated project
   */
  const getProjectIntegrationName = useCallback(
    (
      integrationId: SphereDashboardAPITypes.IntegrationId
    ): string | undefined => {
      if (!projectIntegrations) {
        return undefined;
      }
      const integration = projectIntegrations[integrationId];
      if (
        isAutodeskLegacyProjectIntegration(integration) ||
        isProcoreLegacyProjectIntegration(integration)
      ) {
        return integration.projectName;
      }
      return undefined;
    },
    [projectIntegrations]
  );

  return {
    integrationsService,
    enabledWorkspaceIntegrations,
    enabledProjectIntegrations,
    connectProject,
    disconnectProject,
    getProjectIntegrationName,
    isLoadingIntegrations:
      isLoadingProjectIntegrations || isLoadingWorkspaceIntegrations,
    selectedDialog,
    cancelReauthorization,
    projectDialogType,
    setProjectDialogType,
    shouldShowErrorPage,
  };
}
