import { FaroButton } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box } from "@mui/material";
import { colorConst } from "@styles/common-colors";

interface Props {
  onClick: React.MouseEventHandler;
  /** Flag if the button is only visible & enabled because of dev mode. */
  isDev?: boolean;
  /** Flag to disable the button. */
  isDisabled: boolean;
  /** The tooltip if disabled is set to true. */
  disabledTooltip?: string;
}

/** Button to import ELS scan data */
export function ImportDataButton({ onClick, isDev, isDisabled, disabledTooltip }: Props): JSX.Element {
  isDev = isDev || false;
  const tooltip = isDisabled ? disabledTooltip :
    <div>
      <div style={{ fontWeight: "bold", fontSize: "14px" }}>
        Upload from your computer
      </div>
      Currently, only Blink data is supported.
    </div>;

  return (
    <SphereTooltip
      dataTestId="sa-upload-data-tooltip"
      boxProps={{
        sx: {
          display: "inline-block",
        },
      }}
      title={tooltip}
    >
      <FaroButton
        isDisabled={isDisabled}
        onClick={onClick}
        sx={{
          backgroundColor: isDev ? colorConst.devFeature : undefined,
          "&:hover": isDev ? {
            backgroundColor: colorConst.devFeature,
            filter: "brightness(90%)",
          } : undefined,
        }}
      >
        <Box data-testid="sa-upload-data-button">
          Upload Data {isDev ? " (Dev)" : ""}
        </Box>
      </FaroButton>
    </SphereTooltip>
  );
}
