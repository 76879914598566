import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { getUserTooltipDisplayName } from "@utils/user-utils";
import { ChangeManagementDialog } from "@pages/project-details/project-overview/change-management/change-management-dialog";
import { MemberTypes } from "@custom-types/member-types";
import { Divider, Stack } from "@mui/material";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { getBottomBorderWithBoxShadow } from "@utils/ui-utils";
import { BaseProjectProps } from "@custom-types/project-types";
import { useState } from "react";

interface Props extends BaseProjectProps {
  /** The project manager to display */
  projectManager: {
    user: MemberTypes | null;
    name: string;
  };
}

/**
 * A field to display the project manager and the group of a project. Clicking the field opens a dialog to change them.
 * The project manager is displayed on the right side and the group on the left.
 */
export function GroupAndProjectManagerField({
  projectManager,
  project,
}: Props): JSX.Element {
  const { isUpdatingProjects } = useAppSelector(fetchingProjectsFlagsSelector);

  const [isAllowedToChangeProjectGroup, setIsAllowedToChangeProjectGroup] =
    useState<boolean>(false);

  return (
    <ChangeManagementDialog
      setIsAllowedToChangeProjectGroup={setIsAllowedToChangeProjectGroup}
    >
      <Stack
        sx={
          isAllowedToChangeProjectGroup
            ? {
                "&:hover": {
                  boxShadow: getBottomBorderWithBoxShadow({
                    color: sphereColors.gray400,
                  }),
                },
              }
            : undefined
        }
        direction="row"
        spacing={0}
      >
        <div style={{ width: "50%" }}>
          <FaroTextField
            label="Group"
            initialValue={project.group?.name ?? "No Group"}
            faroVariant="main"
            fontSize="18px"
            shouldShowTooltip
            cursor={isAllowedToChangeProjectGroup ? "pointer" : undefined}
            isFullWidth={true}
            isDisabled={isUpdatingProjects}
          />
        </div>
        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          sx={{ mx: "10px", mt: "26px" }}
        />
        <div style={{ width: "50%" }}>
          <FaroTextField
            label="Project Manager"
            initialValue={projectManager.name}
            faroVariant="main"
            fontSize="18px"
            shouldShowTooltip
            tooltipProps={{
              title: getUserTooltipDisplayName({ member: projectManager.user }),
            }}
            cursor={isAllowedToChangeProjectGroup ? "pointer" : undefined}
            isFullWidth={true}
            isDisabled={isUpdatingProjects}
            isLoading={isUpdatingProjects}
          />
        </div>
      </Stack>
    </ChangeManagementDialog>
  );
}
