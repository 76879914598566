import {
  ICON_SMALL_STYLE,
  SphereAvatar,
} from "@components/header/sphere-avatar";
import { Box } from "@mui/material";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { EDecimalToHex, colorConst, sphereColors } from "@styles/common-colors";
import {
  DEFAULT_FONT_FAMILY_ITALIC,
  withEllipsis,
} from "@styles/common-styles";
import {
  getPrettyName,
  getUserInitials,
  isMemberActive,
} from "@utils/user-utils";
import { useMemo } from "react";
import SelectorArrow from "@assets/icons/selector_arrow.svg?react";
import UserIcon from "@assets/icons/new/user_32px.svg?react";
import EmailIcon from "@assets/icons/new/email_32px.svg?react";
import { addTransparency } from "@utils/ui-utils";
import Team from "@assets/icons/new/team.svg?react";
import { isTeam } from "@utils/team-utils";
import { AutocompleteOptionType } from "@components/common/members-autocomplete/members-autocomplete-types";
import { useAppSelector } from "@store/store-helper";
import { teamsSelector } from "@store/teams/teams-selector";

/** Defines the type of the member to be selected */
export type SelectedMember =
  /** String means that a new member will be invited by email */
  | string
  /** A group member means that an existing member was selected */
  | SphereDashboardAPITypes.IGroupMemberDetails
  /** Null means that no member was selected */
  | null;

/**
 * Used to map the details of the current selection to something
 * that this component can show inside the box.
 */
interface SelectionDetails {
  /** The first row of the element, normally containing the member's name */
  title: string;

  /** The second row of the element, normally containing the member's email. */
  subtitle?: string;

  /**
   * Flag whether the component is showing just the placeholder.
   * This is used to change the style of the component when no member have been selected.
   */
  isPlaceholder: boolean;

  /** The initials of the selected member. */
  initials?: string;

  /** The avatar of the selected member, */
  avatarSrc?: string;

  /** Optional icon to be shown, for example an email icon if a new member will be invited. */
  avatarIcon?: JSX.Element;

  /** The type that will represent the member */
  type?: AutocompleteOptionType | string;
}

interface Props {
  /** Stores the member that the user selected from the autocomplete or the email that the user typed. */
  selectedMember?: SelectedMember;

  /** Text to be shows as placeholder in the selection box when no member has been selected. */
  placeholder: string;

  /**
   * Triggered when the user clicks on this component and it is not disabled.
   */
  onClick: () => void;

  /** Optional flag whether clicking should be disabled */
  isDisabled?: boolean;

  /**
   * Optional flag whether the whole component should be hidden.
   * Useful to alternate in the parent between showing a text field and this component.
   */
  isHidden?: boolean;
}

// The width of the wrapper for the avatar in pixels.
const AVATAR_WIDTH = 50;

// The width of the wrapper for the arrow in pixels.
const DOWN_ARROW_WIDTH = 30;

/**
 * This component is intended to be used inside member-autocomplete
 * And shows the selection for a single user from an autocomplete dropdown.
 */
export function MemberAutocompleteSelection({
  selectedMember,
  placeholder,
  onClick,
  isDisabled = true,
  isHidden = false,
}: Props): JSX.Element {
  const teams = useAppSelector(teamsSelector);

  /**
   * Defines the text and other elements that will be shown in the text.
   * This is useful so that the JSX part of the component is as generic as possible.
   */
  const selectionDetails: SelectionDetails = useMemo(() => {
    /** If an object was selected it means that an existing member was selected. */
    if (selectedMember && typeof selectedMember === "object") {
      if (isTeam(selectedMember)) {
        const item = teams.find((team) => team.identity === selectedMember.identity);

        return {
          title: getPrettyName(selectedMember),
          subtitle: `${item?.memberCount} members`,
          type: "team",
          isPlaceholder: false,
        };
      } else {
        return {
          title: getPrettyName(selectedMember),
          subtitle: isMemberActive(selectedMember)
            ? selectedMember.email
            : undefined,
          isPlaceholder: false,
          initials: getUserInitials(selectedMember),
          type: "member",
          avatarSrc: selectedMember.thumbnailUrl,
          avatarIcon: undefined,
        };
      }
    }

    /** If a string was selected it means that a new member will be invited by email. */
    if (selectedMember && typeof selectedMember === "string") {
      return {
        title: selectedMember,
        subtitle: undefined,
        isPlaceholder: false,
        initials: undefined,
        avatarSrc: undefined,
        avatarIcon: <EmailIcon style={ICON_SMALL_STYLE} />,
      };
    }

    /** Otherwise no member was selected. Show the placeholder in this case. */
    return {
      title: placeholder,
      subtitle: undefined,
      isPlaceholder: true,
      initials: undefined,
      avatarSrc: undefined,
      avatarIcon: <UserIcon style={ICON_SMALL_STYLE} />,
    };
  }, [selectedMember, placeholder, teams]);

  /** Defines the font color of the text based on whether it is disabled and/or a placeholder is shown. */
  const fontColor = useMemo(() => {
    if (isDisabled) {
      return addTransparency({
        color: sphereColors.gray500,
        alpha: EDecimalToHex.hundredTwo,
      });
    }
    return selectionDetails.isPlaceholder
      ? colorConst.placeholder
      : colorConst.normalFont;
  }, [isDisabled, selectionDetails]);

  return (
    <Box
      component="div"
      onClick={() => !isDisabled && onClick()}
      sx={{
        height: "60px",
        display: isHidden ? "none" : "flex",
        alignItems: "center",
        width: "100%",
        backgroundColor: isDisabled
          ? sphereColors.gray50
          : sphereColors.pureWhite,
        border: `2px solid ${sphereColors.gray100}`,
        borderRadius: "5px",
        padding: "10px",
        paddingRight: "0px",
        "&:hover": {
          cursor: isDisabled ? "default" : "pointer",
        },
      }}
    >
      <Box
        component="div"
        sx={{
          width: `${AVATAR_WIDTH}px`,
        }}
      >
        {selectionDetails.type === "team" ? (
          <SphereAvatar
            icon={<Team />}
            size="small"
            backgroundColor={sphereColors.gray700}
          />
        ) : (
          <SphereAvatar
            src={selectionDetails.avatarSrc}
            initials={selectionDetails.initials ?? ""}
            icon={selectionDetails.avatarIcon}
          />
        )}
      </Box>
      <Box
        component="div"
        sx={{
          width: `calc(100% - ${AVATAR_WIDTH + DOWN_ARROW_WIDTH}px)`,
          fontFamily: selectionDetails.isPlaceholder
            ? DEFAULT_FONT_FAMILY_ITALIC
            : undefined,
          fontSize: "14px",
          color: fontColor,
        }}
      >
        <Box component="div" sx={{ ...withEllipsis }}>
          <var>{selectionDetails.title}</var>
        </Box>

        {selectionDetails.subtitle && (
          <Box component="div" sx={{ ...withEllipsis }}>
            <var>{selectionDetails.subtitle}</var>
          </Box>
        )}
      </Box>
      <Box
        component="div"
        sx={{
          width: `${DOWN_ARROW_WIDTH}px`,
          display: "flex",
          alignItems: "center",
          paddingLeft: "9px",
        }}
      >
        <SelectorArrow />
      </Box>
    </Box>
  );
}
