import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** List of scopes for each integration */
export const INTEGRATION_SCOPES: { [key in SphereDashboardAPITypes.IntegrationId]: string[]} = {
  [SphereDashboardAPITypes.IntegrationId.autodesk]: ["data:read", "data:write"],
  [SphereDashboardAPITypes.IntegrationId.procore]: [],
};

/** Window message sent when an integration authorization is canceled */
export const CANCELED_AUTHORIZATION_MESSAGE = "OAUTH_ERROR_USER_CANCELED";

/** Error message sent when a re-authorization workflow was not completed */
export const REAUTHORIZATION_NOT_COMPLETED = "reauthorization_not_completed";

