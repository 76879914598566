import { SphereTooltip } from "@components/common/sphere-tooltip";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { Box, Link, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { useTeamUtils } from "@hooks/use-team-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { useState } from "react";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { useToast } from "@hooks/use-toast";
import { deleteTeam } from "@store/teams/teams-slice-thunk";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { withEllipsis } from "@styles/common-styles";
import { fetchingTeamsFlagsSelector } from "@store/teams/teams-selector";
import GenericCloseSvg from "@assets/icons/generic-close_l.svg?react";
import { UserDisplay } from "@components/common/user-display";
import { convertToTeamMemberTypes } from "@store/members/members-slice-utils";

interface Props {
  /** The team data */
  team: SphereDashboardAPITypes.ITeam;
}

/** Render team table action elements */
export function TeamsTableAction({ team }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();
  const { openTeamDetails } = useTeamUtils();
  const { showToast } = useToast();
  const { isRemovingTeam } = useAppSelector(fetchingTeamsFlagsSelector);
  const { canDeleteTeams } = useHasUserValidRoleCompanyLevel();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
  async function onViewDetailsClick(): Promise<void> {
    openTeamDetails({
      teamId: team.id,
      numberOfMembers: team.memberCount,
    });
  }

  /** Handles removing a team */
  async function removeTeam(): Promise<void> {
    trackEvent({
      name: TeamEvents.deleteTeam,
      props: { numberOfMembers: team.memberCount },
    });

    if (!companyId) {
      throw new Error("companyId is required");
    }

    try {
      await dispatch(
        deleteTeam({ coreApiClient, companyId, teamId: team.id })
      ).unwrap();

      showToast({
        type: "success",
        message: `${TEAM_DISPLAY_NAME} deleted successfully`,
      });
    } finally {
      // Error is handled by error slice
    }
  }

  return (
    <Box
      className={SHOW_ON_HOVER_CLASS}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
        visibility: "hidden",
        width: "100%",
      }}
    >
      {/* View details link */}
      <Link
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onClick={onViewDetailsClick}
        underline="always"
        sx={{
          marginRight: "10px",
          color: sphereColors.blue500,
          textDecorationColor: sphereColors.blue500,
          cursor: "pointer",
          ...withEllipsis,
        }}
      >
        View details
      </Link>

      {/* Delete team button */}
      {canDeleteTeams && !team.isManaged && (
        <>
          <SphereActionDivider />
          <SphereTooltip title={`Remove ${TEAM_DISPLAY_NAME}`}>
            <FaroIconButton
              component={GenericCloseSvg}
              onClick={() => setIsDialogOpen(true)}
            />
          </SphereTooltip>
        </>
      )}

      {/* Dialog before a team to be removed */}
      <FaroDialog
        title={`Remove ${TEAM_DISPLAY_NAME}?`}
        confirmText="Remove"
        open={isDialogOpen}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={removeTeam}
        onClose={() => setIsDialogOpen(false)}
        isConfirmLoading={isRemovingTeam}
      >
        <Typography
          sx={{
            fontSize: "14px",
            marginTop: "0px",
            marginBottom: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          This action will only remove the selected {TEAM_DISPLAY_NAME}. All the
          members of the {TEAM_DISPLAY_NAME} will remain in the workspace.
        </Typography>

        <UserDisplay member={convertToTeamMemberTypes(team)} />
      </FaroDialog>
    </Box>
  );
}
