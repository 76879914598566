import { Box, Stack, Step, StepLabel } from "@mui/material";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { AddDataButton } from "@pages/project-details/project-data-management/add-data-button";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { DataManagementProgressBar } from "@pages/project-details/project-data-management/data-management-stepper/data-management-progress-bar";
import { StepState } from "@pages/project-details/project-data-management/data-management-types";
import { FaroPopoverAction } from "@components/common/faro-popover-action";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props extends BaseProjectIdProps {
  uploadStepState: StepState;
  /** Setter for showing or hiding the upload dialog. */
  setIsUploadDialogOpen(isUploadDialogOpen: boolean): void;
  progress: number;
  /** Flag to show the AddDataButton button. */
  isUploadBtnVisible: boolean
}

export function UploadStep({
  uploadStepState,
  projectId,
  setIsUploadDialogOpen,
  isUploadBtnVisible,
  progress,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  return (
    <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <Step
          active={uploadStepState === "active"}
          completed={uploadStepState === "done"}
        >
          <FaroPopoverAction
            title="Upload Failed"
            description="An error occurred during the upload. Please try uploading again."
            actionTitle="Upload Again"
            action={() => {
              setIsUploadDialogOpen(true);
              trackEvent({
                name: DataManagementEvents.addData,
                props: { projectId },
              });
            }}
            isEnabled={uploadStepState === "error"}
          >
            <StepLabel StepIconComponent={DataManagementStepIcon} error={uploadStepState === "error"}>
              Upload
            </StepLabel>
          </FaroPopoverAction>
        </Step>
        <DataManagementProgressBar progress={progress} />
      </Stack>
      <Box sx={{
        width: "fit-content",
        marginLeft: "28px",
      }}>
        { uploadStepState !== "error" && isUploadBtnVisible &&
          <AddDataButton projectId={projectId} setIsUploadDialogOpen={setIsUploadDialogOpen} />
        }
      </Box>
    </Stack>
  );
}
