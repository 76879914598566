import {
  BaseCaptureTreeEntity,
  ClusterEntity,
  EntityMaps,
  RootEntity,
  SCAN_ENTITY_TYPES,
  ScanEntity,
} from "@custom-types/capture-tree-types";
import { assert, GUID } from "@faro-lotv/foundation";
import { CaptureTreeEntityType, Transformation } from "@faro-lotv/service-wires";
import { getExternalScanId } from "@pages/project-details/project-data-management/data-management-utils";
import { UUID } from "@stellar/api-logic/dist/api/core-api/api-types";

/** Path of the RootEntity in the Capture Tree and in LsDataV2. */
export const ROOT_ENTITY_PATH = getEntityPath("", "root");

/**
 * Returns a name-path for an entity.
 * Used to identify LsDataV2 clusters in the Capture Tree, since they don't have an "externalId".
 * The JSON encoding is used to ensure that two different lists of names never return the same path
 * (consider e.g. ["root", "C1"] vs. ["root,C1"]).
 * @param parentPath Encoded name-path of the parent entity.
 * @param name Name of the entity.
 * @returns Encoded name-path of the entity.
 */
export function getEntityPath(parentPath: string, name: string): string {
  const encodedName = JSON.stringify(name);
  return parentPath ? `${parentPath},${encodedName}` : encodedName;
}

/**
 * Alternative version of getEntityPath that accepts an array of names.
 * @param names Ordered list of names: [root.name, ..., parentEntity.name, entity.name].
 * @returns Encoded name-path of the entity.
 */
export function getEntitiesPath(names: string[]): string {
  return names.map((name) => getEntityPath("", name)).join(",");
}

/**
 * @returns A new instance of an identity transformation for Capture Tree / ProjectAPI.
 *          This means you can safely modify any property of the returned object.
 */
export function getIdentityPose(): Transformation {
  return {
    pos: { x: 0, y: 0, z: 0 },
    rot: { x: 0, y: 0, z: 0, w: 1 },
  };
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a root.
 */
export function isRootEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is RootEntity<T> {
  return entity.type === CaptureTreeEntityType.root && entity.parentId === null;
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a cluster.
 */
export function isClusterEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is ClusterEntity<T> {
  return entity.type === CaptureTreeEntityType.cluster;
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a scan.
 */
export function isScanEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is ScanEntity<T> {
  return Object.values<CaptureTreeEntityType>(SCAN_ENTITY_TYPES).includes(
    entity.type
  );
}

/**
 * @param entities The capture tree entities
 * @returns The root entity or undefined if not found
 */
export function getRootEntity<T extends BaseCaptureTreeEntity>(
  entities: T[]
): RootEntity<T> | undefined {
  return entities.find(isRootEntity);
}

/**
 * @param entities The capture tree entities
 * @returns The cluster entities
 */
export function getClusterEntities<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T>[] {
  return entities.filter(isClusterEntity);
}

export function getEntityMaps<T extends BaseCaptureTreeEntity>(
  entities: T[]
): EntityMaps<T> {
  const entityById: Record<GUID, T> = Object.fromEntries(
    entities.map((entity) => [entity.id, entity])
  );
  const pathById: Record<GUID, string> = {};
  const idByPath: Record<string, GUID> = {};
  const scanIdByUuid: Record<UUID, GUID> = {};

  // Sort the entities so we get a consistent order, since paths may be duplicated.
  const entitiesSorted = [...entities].sort(
    (a, b) => a.id.localeCompare(b.id, "en")
  );

  const rootEntity = getRootEntity(entities);
  if (!rootEntity) {
    assert(entities.length === 0, "RootEntity is required if > 0 entities are present");
    return { entityById, pathById, idByPath, scanIdByUuid };
  }
  pathById[rootEntity.id] = ROOT_ENTITY_PATH;

  // eslint-disable-next-line no-constant-condition -- We exit the loop using "return" once all entities have a path.
  while (true) {
    let isChanged = false;
    for (const entity of entitiesSorted) {
      assert(entity.id, "Entity ID is required");
      if (!entity.parentId || pathById[entity.id]) {
        continue;
      }
      const parentPath  = pathById[entity.parentId];
      if (parentPath) {
        pathById[entity.id] = getEntityPath(parentPath, entity.name);
        isChanged = true;
      }
    }
    if (!isChanged) {
      break;
    }
  }

  for (const [id, path] of Object.entries(pathById)) {
    idByPath[path] = id;
  }

  for (const entity of entitiesSorted) {
    const externalId = getExternalScanId(entity);
    if (externalId) {
      scanIdByUuid[externalId] = entity.id;
    }
  }

  return { entityById, pathById, idByPath, scanIdByUuid };
}
