import {
  TeamMemberBulkButtonComponents,
  TeamMemberTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { useMemo } from "react";
import { BulkMemberCsvExport } from "@components/table/members/bulk-actions/bulk-member-csv-export";
import { SdbTeamMemberBase } from "@custom-types/teams-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { BulkRemoveMember } from "@components/table/members/bulk-actions/bulk-remove-member";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { isMemberActive } from "@utils/user-utils";
import { BulkSendEmail } from "@components/table/members/bulk-actions/bulk-send-email";
import { BulkResendInvitation } from "@components/table/members/bulk-actions/bulk-resend-invitation";

interface Props extends BaseCompanyIdProps {
  /** Selected team details */
  team: SphereDashboardAPITypes.ITeam;

  /** List of members of the team to process in bulk actions */
  teamMembers: SdbTeamMemberBase[];
}

/** List all the bulk action components for team members table */
export function TeamMembersPageBulkActions({
  companyId,
  team,
  teamMembers,
}: Props): TeamMemberBulkButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));
  const isAnyRowSelected = selectedEntities.length !== 0;
  const isAnyMemberPending = selectedEntities.some(
    (member) => !isMemberActive(member)
  );
  const { canDeleteMembersFromTeam, canDeleteMemberFromCompany } =
    useHasUserValidRoleCompanyLevel();

  const allowedButtonsBasedOnRowSelection: TeamMemberTableBulkName[] =
    useMemo(() => {
      const allowedButtons: TeamMemberTableBulkName[] = ["exportCsv"];

      if (isAnyRowSelected) {
        allowedButtons.push("sendEmail");

        if (isAnyMemberPending) {
          allowedButtons.push("resendInvitation");
        }
        if (canDeleteMembersFromTeam && !team.isManaged) {
          allowedButtons.push("removeMember");
        }
        if (canDeleteMemberFromCompany) {
          allowedButtons.push("removeMemberFromWorkspace");
        }
      }

      return allowedButtons;
    }, [
      isAnyRowSelected,
      isAnyMemberPending,
      canDeleteMembersFromTeam,
      team.isManaged,
      canDeleteMemberFromCompany,
    ]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  return {
    resendInvitation: shouldShowBulkButtons("resendInvitation") ? (
      <BulkResendInvitation
        selectedEntities={selectedEntities}
        companyId={companyId}
        subjectType="team"
      />
    ) : null,

    removeMember: shouldShowBulkButtons("removeMember") ? (
      <BulkRemoveMember
        subjectType="team"
        companyId={companyId}
        teamId={team.id}
        title="remove from team"
      />
    ) : null,

    removeMemberFromWorkspace: shouldShowBulkButtons(
      "removeMemberFromWorkspace"
    ) ? (
      <BulkRemoveMember
        subjectType="workspaceTeam"
        companyId={companyId}
        title="remove from Workspace"
        teamId={team.id}
      />
    ) : null,

    sendEmail: shouldShowBulkButtons("sendEmail") ? (
      <BulkSendEmail selectedEntities={selectedEntities} />
    ) : null,

    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <BulkMemberCsvExport
        selectedEntities={isAnyRowSelected ? selectedEntities : teamMembers}
        subjectType="team"
        entityName={team.name}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
