import { SdbProject } from "@custom-types/project-types";
import { ProjectMemberRole } from "@components/table/members/members-column/role-column/project-member-role";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { MemberIdentityRoleProps } from "@custom-types/member-types";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { formatUserRoleType } from "@utils/data-display";
import { CompanyMemberRole } from "@components/table/members/members-column/role-column/company-member-role";
import { GroupMemberRole } from "@components/table/members/members-column/role-column/group-member-role";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";

interface Props extends BaseCompanyIdProps, MemberIdentityRoleProps {
  /** The selected group if there is one */
  group?: SphereDashboardAPITypes.IGroupDetails | null;

  /** The selected project if there is one */
  selectedProject?: SdbProject | null;

  /** Recognizes on which table subject this component is being used */
  subjectType: MemberTableSubject;
}

/** Content to show for role member column */
export function RoleColumn({
  companyId,
  selectedProject,
  subjectType,
  memberIdentityRole,
  group,
}: Props): JSX.Element | null {
  if (subjectType === "project" && selectedProject) {
    return (
      <ProjectMemberRole
        companyId={companyId}
        projectId={selectedProject?.id}
        memberIdentityRole={memberIdentityRole}
      />
    );
  }

  if (subjectType === "snapshot") {
    return (
      <SphereTooltip
        boxProps={{ sx: withEllipsis }}
        shouldShowTooltip={false}
        title={memberIdentityRole.role}
      >
        {formatUserRoleType(memberIdentityRole.role, {
          projectContext: "snapshot",
        })}
      </SphereTooltip>
    );
  }

  if (subjectType === "workspace" || subjectType === "team") {
    return (
      <CompanyMemberRole
        companyId={companyId}
        memberIdentityRole={memberIdentityRole}
      />
    );
  }

  if (subjectType === "group" && group) {
    return (
      <GroupMemberRole
        companyId={companyId}
        selectedGroup={group}
        memberIdentityRole={memberIdentityRole}
      />
    );
  }

  return null;
}
