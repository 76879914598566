import { assert } from "@faro-lotv/foundation";

/**
 * Asserts that the given value is truthy.
 *
 * @param value The value to assert.
 * @param message Optional error message.
 * @returns value with NonNullable type.
 * @throws {Error} if value is falsy.
 *
 * @example `getFileExtension(assertValue(filenameOrUndefined));`
 */
export function assertValue<T>(value: T, message?: string): NonNullable<T> | never {
  assert(value, message);
  return value;
}

