import { runtimeConfig } from "@src/runtime-config";

/** The regions the app is running in. Either EU or US. */
export const serverRegion = runtimeConfig.appEnv.includes("eu") ? "eu" : "us";

/** Whether the app is running on localhost or not */
export const isLocalhostEnvironment = window.location.hostname === "localhost";

/** Whether the app is running on production or not (staging or dev) */
export const isProductionEnvironment =
  runtimeConfig.environment === "production";

/** Whether the app is running on pre-production environments (staging or dev) or not */
export const isPreProductionEnvironment =
  runtimeConfig.environment === "dev" ||
  runtimeConfig.environment === "staging";
